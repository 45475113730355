<template>
  <div class="horus-zoom-document container">
    <div class="navbar">
      <div class="main-bar">
        <a href="/landing"><img src="/static/images/narrative_icon_w.png" class="navi-logo-icon"></a>
      </div>
    </div>
    <div class="contents">
      <div class="main-contents jp" v-if="displayLang === 'jp'">
        <h1>Zoom利用のための解説<a href="/zoom_document/en" class="switch-lang">English</a></h1>
        <h2>サインイン</h2>
        <div class="description">
          <p><a href="/landing">ログインページ</a>にアクセスし、ログインIDとパスワードを入力し、「ログイン」ボタンをクリックする</p>
          <div class="fig">
            <img src="/static/page_img/fig_landing.png"/>
            <ul class="fig-caption">
              <li>A. ログインID入力フィールド</li>
              <li>B. パスワード入力フィールド</li>
              <li>C. パスワード表示切り替えチェックボックス</li>
              <li>D. ログインボタン</li>
            </ul>
          </div>
          <p>メインタイムラインページに遷移する</p>
        </div>
        <h2>Zoomアカウントの設定(Zoomアプリの認証)</h2>
        <div class="description">
          <ol>
            <li>
              トップのナビゲーションバー右側、ポートレートをクリックしてメインメニューを展開する
              <div class="fig">
                <img src="/static/page_img/fig_timeline_main_menu.png" />
                <ul class="fig-caption">
                  <li>A. ポートレート</li>
                  <li>B. メインメニュー</li>
                  <li>C. プロフィール</li>
                </ul>
              </div>
            </li>
            <li>「プロフィール」をクリックして設定ページに遷移する</li>
            <li>
              画面下部、「外部連携設定」の「Zoomと連係する」をクリック
              <div class="fig">
                <img src="/static/page_img/fig_profile_page.png" />
                <ul class="fig-caption">
                  <li>A. 外部連携設定</li>
                  <li>B. Zoom連携ボタン</li>
                  <li>C. メインタイムラインページリンク</li>
                </ul>
              </div>
            </li>
            <li>
              zoomアカウントの接続モーダルが表示されるので、「はい」をクリック
              <div class="fig">
                <img src="/static/page_img/fig_zoom_relation_start.png" />
              </div>
            </li>
            <li>
              Zoomのアプリケーション登録ページに遷移するので、Zoomにログインして「アプリケーション利用」を許可
            </li>
            <li>
              プロフィールページに戻り、「Zoomアカウントの連携完了」ダイアログが出現するので、「OK」をクリックしてダイアログを閉じる
              <div class="notify">ナラティブブックのアカウントと連携しているZoomアカウントを識別するために、Zoomのユーザー情報を取得し、ナラティブブックに格納しています。<br/>取得し、格納する情報は、Zoomに登録したアカウントID、メールアドレス、姓、名です。これらの情報は、Zoom連携を解除した際には、ナラティブブックから削除されます。</div>
              <div class="fig">
                <img src="/static/page_img/fig_zoom_relation_finish.png" />
              </div>
            </li>
          </ol>
        </div>
        <h2>アドレス帳からZoom発信する</h2>
        <div class="description">
          <ol>
            <li>
              ヘッダーバーの左上アイコンか、プロフィール写真クリックで展開するメインメニューの「メインタイムライン」を選択してメインタイムラインに移動する
            </li>
            <li>
              サブバー(メインバーの下にあるグレーのバー)の右から2番めのアドレス帳アイコンをクリックしてアドレス帳を展開する
              <div class="fig">
                <img src="/static/page_img/fig_timeline_addressbook.png" />
                <ul class="fig-caption">
                  <li>A. サブバー</li>
                  <li>B. アドレス帳アイコン</li>
                  <li>C. ビデオ通話アイコン</li>
                </ul>
              </div>
            </li>
            <li>通話したい相手のアイコンの横のビデオ通話アイコンをクリック</li>
            <li>
              ビデオ通話作成モーダルが展開するので、参加者を追加したい場合は、「参加者を追加」ボタンを押して参加者を追加
              <div class="fig">
                <img src="/static/page_img/fig_canf_configure_modal.png" />
                <ul class="fig-caption">
                  <li>A. 主通話先</li>
                  <li>B. 参加者追加ボタン</li>
                  <li>C. 日時設定ボタン</li>
                  <li>D. 通話開始ボタン</li>
                </ul>
              </div>
            </li>
            <li>通話日時を設定したい場合は、日時設定ボタンをクリックして日時を指定する(日時を設定した場合、通話開始ボタンの表示が「ビデオ会議を予約」に変更される)</li>
            <li>通話開始ボタンをクリック</li>
            <li>
              日時を指定しない場合、すぐにビデオ会議開始ダイアログが表示される。「はい」をクリックすると、Zoomが立ち上がるので会議を開始する
              <div class="fig">
                <img src="/static/page_img/fig_close_and_start_conf.png" />
                <ul class="fig-caption">
                  <li>A. 通知アイコン</li>
                  <li>B. 未読通知バッジ</li>
                  <li>C. 通知リスト</li>
                </ul>
              </div>
            </li>
          </ol>
        </div>
        <h2>着信側及び日時指定の際の操作</h2>
        <div class="description">
          <ol>
            <li>
              会議開始時刻になると、ナビゲーションバー右端の通知アイコンに通知が追加され、バッジが表示される。
              <div class="fig">
                <img src="/static/page_img/fig_receiver_home.png" />
                <ul class="fig-caption">
                  <li>A. 通知アイコン</li>
                  <li>B. 未読通知バッジ</li>
                  <li>C. 通知リスト</li>
                </ul>
              </div>
            </li>
            <li>通知リストを展開し、対象の通知をクリックすると、Zoomが立ち上がるので、会議を開始する</li>
          </ol>
        </div>
        <h2>Zoomアカウント連携の解除</h2>
        <div class="description">
          <ol>
            <li>
              トップのナビゲーションバー右側、ポートレートをクリックしてメインメニューを展開する
              <div class="fig">
                <img src="/static/page_img/fig_timeline_main_menu.png" />
                <ul class="fig-caption">
                  <li>A. ポートレート</li>
                  <li>B. メインメニュー</li>
                  <li>C. プロフィール</li>
                </ul>
              </div>
            </li>
            <li>「プロフィール」をクリックして設定ページに遷移する</li>
            <li>
              画面下部、「外部連携設定」の「Zoomとの連携を解除する」をクリック
              <div class="fig">
                <img src="/static/page_img/fig_profile_installed.png" />
                <ul class="fig-caption">
                  <li>A. 外部連携設定</li>
                  <li>B. Zoom連携解除ボタン</li>
                  <li>C. メインタイムラインページリンク</li>
                </ul>
              </div>
            </li>
            <li>
              新しいタブ（またはウィンドウ）でZoomマーケットプレースのインストール済みアプリ一覧が表示されるので、「Narrativebook」の列の「Uninstall」ボタンをクリックする
              <div class="fig">
                <img src="/static/page_img/fig_zoom_market_list.png" />
              </div>
            </li>
            <li>
              上記ページでアンインストール用のモーダルが表示されるので、理由(Please choose your uninstall reason)を一つ選択して「Uninstall」ボタンをクリックする
              <div class="fig">
                <img src="/static/page_img/fig_zoom_market_uninstall_modal.png" />
              </div>
            </li>
            <li>元のページ戻って、「外部連携設定」のZoomの項目が「Zoomの連携情報はありません」となっていれば完了</li>
          </ol>
        </div>
      </div>
      <div class="main-contents en" v-if="displayLang === 'en'">
        <h1>Explanation for using Zoom<a href="/zoom_document" class="switch-lang">日本語</a></h1>
        <h2>Signin for Narrativebook</h2>
        <div class="description">
          <p>Access to the <a href="/landing">sign-in URL</a>, enter the login ID and password in each field, click on the "Login<span class="ja-description">(ログイン)</span>" button</p>
          <div class="fig">
            <img src="/static/page_img/fig_landing.png"/>
            <ul class="fig-caption">
              <li>A. Login ID input field</li>
              <li>B. Password entry field</li>
              <li>C. Password display switching check box</li>
              <li>D. "Login" button</li>
            </ul>
          </div>
          <p>Transition to the main timeline page</p>
        </div>
        <h2>Zoom account settings (Zoom app authentication)</h2>
        <div class="description">
          <ol>
            <li>
              Click Portrait on the right side of the top navigation bar to expand the main menu
              <div class="fig">
                <img src="/static/page_img/fig_timeline_main_menu.png" />
                <ul class="fig-caption">
                  <li>A. Portrait</li>
                  <li>B. main menu</li>
                  <li>C. "profile" link</li>
                </ul>
              </div>
            </li>
            <li>Click the "Profile(プロフィール)" link to move to the settings page</li>
            <li>
              At the bottom of the screen, click "Link with Zoom(Zoomと連携する)" in "External link settings<span class="ja-description">(外部連携設定)</span>"
              <div class="fig">
                <img src="/static/page_img/fig_profile_page.png" />
                <ul class="fig-caption">
                  <li>A. External link settings</li>
                  <li>B. "Link with Zoom" button</li>
                  <li>C. main timeline page link</li>
                </ul>
              </div>
            </li>
            <li>
              The Zoom account connection modal will be displayed, so click "Yes<span class="ja-description">(はい)</span>".
              <div class="fig">
                <img src="/static/page_img/fig_zoom_relation_start.png" />
              </div>
            </li>
            <li>
              You will be taken to the Zoom application registration page, so log in to Zoom and allow "application use".
            </li>
            <li>
              When you return to the profile page, the "Zoom account linkage complete<span class="ja-description">(Zoomアカウントの連携完了)</span>" dialog will appear. Click "OK" to close the dialog.
              <div class="notify">In order to identify the Zoom account linked with the narrative book account, Zoom user information is acquired and stored in the narrative book.<br/>The information to be acquired and stored is the account ID, email address, last name, and first name registered in Zoom. This information will be deleted from the narrative book when the Zoom linkage is canceled.</div>
              <div class="fig">
                <img src="/static/page_img/fig_zoom_relation_finish.png" />
              </div>
            </li>
          </ol>
        </div>
        <h2>Make a Zoom video call from your address book</h2>
        <div class="description">
          <ol>
            <li>
              Go to the main timeline by selecting the "Main Timeline<span class="ja-description">(メインタイムライン)</span>" from the main menu that expands by clicking the icon on the upper left of the header bar or clicking the profile picture.
            </li>
            <li>
              Expand the address book by clicking the second address book icon from the right on the sub bar (the gray bar below the main bar)
              <div class="fig">
                <img src="/static/page_img/fig_timeline_addressbook.png" />
                <ul class="fig-caption">
                  <li>A. sub bar</li>
                  <li>B. address book icon</li>
                  <li>C. video call icon</li>
                </ul>
              </div>
            </li>
            <li>Click the video call icon next to the icon of the person you want to call</li>
            <li>
              Video call creation modal unfolds
              <div class="fig">
                <img src="/static/page_img/fig_canf_configure_modal.png" />
                <ul class="fig-caption">
                  <li>A. Main call destination</li>
                  <li>B. Add Participant Button</li>
                  <li>C. Date and time setting button</li>
                  <li>D. Call start button</li>
                </ul>
              </div>
            </li>
            <li>If you want to add a participant, click the "Add Participant<span class="ja-description">(参加者を追加)</span>" button to add a participant</li>
            <li>If you want to set the call date and time, click the date and time setting button and specify the date and time (if you set the date and time, the display of the call start button will be changed to "Reserve video conference<span class="ja-description">(ビデオ会議を予約)</span>")</li>
            <li>Click the call start button</li>
            <li>
              If you do not specify a date and time, the video conference start dialog is displayed immediately. Click "Yes<span class="ja-description">(はい)</span>" to launch Zoom and start the meeting.<br/>
              If you specify a date and time, a video conference reservation will be created and you will be notified when the meeting start time is reached.
            </li>
          </ol>
        </div>
        <h2>Called side Operation and Calling side Operation at date specified conference</h2>
        <div class="description">
          <ol>
            <li>
              At the meeting start time, a notification will be added to the notification icon on the far right of the navigation bar and a badge will be displayed.
              <div class="fig">
                <img src="/static/page_img/fig_receiver_home.png" />
                <ul class="fig-caption">
                  <li>A. Notification icon</li>
                  <li>B. Unread notification badge</li>
                  <li>C. Notification list</li>
                </ul>
              </div>
            </li>
            <li>Expand the notification list, click the target notification, Zoom will be launched, and start the meeting</li>
          </ol>
        </div>
        <h2>Cancellation of Zoom account linkage(Uninstall Zoom marketplace app)</h2>
        <div class="description">
          <ol>
            <li>
              Click Portrait on the right side of the top navigation bar to expand the main menu
              <div class="fig">
                <img src="/static/page_img/fig_timeline_main_menu.png" />
                <ul class="fig-caption">
                  <li>A. Portrait</li>
                  <li>B. main menu</li>
                  <li>C. "profile" link</li>
                </ul>
              </div>
            </li>
            <li>Click the "Profile(プロフィール)" link to move to the settings page</li>
            <li>
              At the bottom of the screen, click "Cancel the link with Zoom<span class="ja-description">(Zoomとの連携を解除する)</span>" in "External link settings<span class="ja-description">(外部連携設定)</span>"
              <div class="fig">
                <img src="/static/page_img/fig_profile_installed.png" />
                <ul class="fig-caption">
                  <li>A. External link settings</li>
                  <li>B. "Cancel the link with Zoom" button</li>
                  <li>C. main timeline page link</li>
                </ul>
              </div>
            </li>
            <li>
              A new tab (or window) will display a list of installed apps on the Zoom Marketplace, so click the "Uninstall" button in the "Narrative book" column.
              <div class="fig">
                <img src="/static/page_img/fig_zoom_market_list.png" />
              </div>
            </li>
            <li>
              The uninstall modal will be displayed on the page, so choose your uninstall reason and click the "Uninstall" button.
              <div class="fig">
                <img src="/static/page_img/fig_zoom_market_uninstall_modal.png" />
              </div>
            </li>
            <li>Go back to the original page and complete if the Zoom item in "External Linkage Settings<span class="ja-description">(外部連携設定)</span>" is "No Zoom Linkage Information<span class="ja-description">(Zoomの連携情報はありません)</span>"</li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HorusZoomDocument',

  props: {
    lang: String
  },

  data () {
    return {
      displayLang: 'jp'
    }
  },

  watch: {
    '$attrs': { handler: 'initLang', immediate: true }
  },

  methods: {
    initLang () {
      if (this.lang[0] === 'en') {
        this.displayLang = 'en'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mediaQuery.scss';

.horus-zoom-document {
  background-color: #eee;
  .contents {
    min-height: calc(100% - 50px - 40px - 132px);
    padding: 20px 60px;
    background-color: #fff;
    @include mediaQuery('phone') {
      padding: 10px 20px;
    }
    font-size: 1.1em;
    h1 {
      margin: 0;
      padding: 0;
      font-size: 1.4em;
      font-weight: bold;
      .switch-lang {
        padding-left: 20px;
        font-size: 12px;
        font-weight: normal;
      }
    }
    h2 {
      margin: 0;
      margin-top: 30px;
      font-size: 1.1em;
      font-weight: bold;
    }
    h3 {
      margin: 0;
      margin-top: 10px;
      font-size: 1em;
      font-weight: bold;
    }
    p {
      margin: 5px 0;
    }
    p.right-align {
      text-align: right;
    }
    ol {
      margin: 10px 0;
      padding-inline-start: 20px;
      @include mediaQuery('phone') {
        padding-inline-start: 10px;
      }
      li {
        margin: 8px;
        div.notify {
          margin: 5px 0;
          padding: 5px;
          border: 1px solid #c85554;
          font-size: 0.9em;
        }
      }
    }
    ul {
      list-style-type: none;
      padding-inline-start: 10px;
      li {
        margin: 8px;
      }
    }
    .main-contents.en {
      word-break: normal;
    }
    .fig {
      margin: 10px 0;
      padding: 10px;
      border: 1px solid #aaa;
      img {
        width: 100%;
      }
    }
    .ja-description {
      font-size: 0.95em;
      color: #c85554;
    }
  }
}
</style>
