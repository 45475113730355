<template>
  <div class="horus-main-timeline-view">
    <horus-sub-header
      :timelineOwner="timelineOwner"
      :globalStyle="globalStyle"
      :nowInTimeline="true"
      v-on:updateTimelineEvent="updateTimeline"
      v-if="subHeaderVisible" />
    <horus-sub-bar
      :pageName="'timeline'"
      :unreadCount="familyTotalUnreadCount"
      :ownTimeline="ownTimeline"
      v-on:toggleLeftEvent="toggleLeftColumn"
      v-on:toggleRightEvent="toggleRightColumn"
      v-on:toggleAddressColumnEvent="toggleAddressColumn"
      v-on:toggleTaskColumnEvent="toggleTaskColumn"
      v-on:showNormalTimelineEvent="switchTimeline('normal')"
      v-on:showAlbumTimelineEvent="switchTimeline('album')"
      v-on:reloadContentEvent="switchTimeline('normal')"/>
    <div class="main-timeline-content" :class="{'sub-header-visible': subHeaderVisible}">
      <transition name="left-column">
        <div class="left-content" v-show="leftColumnOpen">
          <horus-left-column-controll
            :hideDateJump="hideDateJump"
            v-on:openTagNarrowDonModalEvent="openTagNarrowDownModal"
            v-on:openAuthorNarrowDownModalEVent="openAuthorNarrowDownModal"
            v-on:openDateJumpControllEvent="openDateJumpControll" />
          <div class="nudge-records left-column-contents" v-show="timelineRecordType === 'normal' ">
            <h3>
              <a @click="switchNudgedTimeline"><img src="/static/images/nudge_title_icon.png">nudge</a>
            </h3>
            <!-- horus-left-column-nudged-record -->
            <div v-if="nudgeNowLoading" class="now-loading"><img class="progress" src="/static/images/progress_s.gif" />読み込み中...</div>
            <horus-left-column-nudged-record
              v-else
              v-for="nudgedRecord in nudgedRecords.slice(0,5)"
              :key="'left' + nudgedRecord.id"
              :nudgedRecord="nudgedRecord"
              v-on:showNudgedRecordModalEvent="showNudgedRecordModal" />
          </div>
          <!-- wish, info -->
          <horus-left-column-wish-list
            :wishes="wishes"
            :wishesNowLoading="wishesNowLoading"
            v-on:showInfoContentPhotoEvent="showInfoContentPhoto"
            v-on:wishModalOpenEvent="openWishModal"
            v-on:infoContentTitleClickEvent="infoContentTitleClicked" />
          <horus-left-column-info-list
            :boadComments="infos"
            :infosNowLoading="infosNowLoading"
            v-on:showInfoContentPhotoEvent="showInfoContentPhoto"
            v-on:boadCommentModalOpenEvent="openBoadCommentModal"
            v-on:infoContentTitleClickEvent="infoContentTitleClicked" />
          <h3 class="left-column-category">-- my function --</h3>
          <!-- my function vitals -->
          <horus-vital-column
            v-if="omronConnectAvailable"
            :timelineOwner="timelineOwner"
            :vitalDisplayConfig="vitalDisplayConfig"
            :vitalShouldUpdate="vitalShouldUpdate"
            v-on:showVitalDisplayConfigEvent="vitalDisplayConfigShow"
            v-on:showVitalEntryListEvent="vitalEntryListShow"
            v-on:vitalUpdatedEvent="vitalUpdated"
            v-on:vitalForceReloadEvent="vitalForceReload"
            />
        </div>
      </transition>
      <div class="center-content" :class="{wide: !leftColumnOpen && !rightColumnOpen }" v-show="!windowSizeNarrow || ( !leftColumnOpen && !rightColumnOpen )" ref="mainTimeline">
        <div class="normal-timeline" v-show="timelineRecordType === 'normal'">
          <!-- post controll -->
          <horus-record-post-form
            v-if="loggedInAuthor && timelineOwner"
            ref="recordPostForm"
            :signinMember="signinMember"
            :author="loggedInAuthor"
            :timelineOwner="timelineOwner"
            :referenceInfoContent="referenceInfoContent"
            :referenceInfoContentType="referenceInfoContentType"
            :quatedRecord="quatedRecordForPost"
            :selectedTags="selectedTagsForPost"
            :createTags="createTagsForPost"
            :pushTargets="pushTargetsForPost"
            :medicalExamination="medicalExaminationForPost"
            :drugEntry="drugEntryForPost"
            :targetDate="postTargetDate"
            :postFormAvailable="postFormAvailable"
            :postFormActive="postFormActive"
            v-on:activatePostFormEvent="activatePostForm"
            v-on:removeReferenceEvent="removeReferenceForPost"
            v-on:removeQuatedRecordEvent="removeQuatedRecord"
            v-on:openTagSelectionModalEvent="tagSelectionForPost"
            v-on:openPushTargetSelectionModalEvent="pushTargetSelectionForPost"
            v-on:removeSelectedTagEvent="removeSelectedTagFromPost"
            v-on:removeCreateTagEvent="removeNewPostTagForPost"
            v-on:updateSurrogateTagEvent="updateSurrogateTag"
            v-on:removeSelectedAuthorEvent="removePushTargetAuthorForPost"
            v-on:setTargetDateForPostEvent="targetDateSelectionForPost"
            v-on:clearTargetDateForPostEvent="clearTargetDateForPost"
            v-on:openMedicalExaminationModalEvent="openMedicalExaminationEditModal"
            v-on:openDrugEntryModalEvent="openDrugEntryEditModal"
            v-on:showNotifyEvent="showNotifyDialog"
            v-on:showConfirmEvent="showConfirmDialog"
            v-on:postformClearEvent="confirmPostFormClear"
            v-on:submitPostFormEvent="submitPostForm"
            v-on:showInfoContentPhotoEvent="showInfoContentPhoto"
            />
          <!-- timeline narrow down controll -->
          <horus-narrow-down-hash-tags
            v-show="narrowDownTags.length > 0"
            :narrowDownTags="narrowDownTags"
            v-on:removeNarrowDownTagEvent="removeNarrowDownTag" />
          <horus-narrow-down-authors
            v-show="narrowDownAuthors.length > 0"
            :narrowDownAuthors="narrowDownAuthors"
            v-on:removeNarrowDownAuthorEvent="removeNarrowDownAuthor" />
          <!-- timeline records -->
          <horus-normal-record-timeline v-if="timelineRecordType === 'normal'"
            :loggedInUser="loggedInUser"
            :signinMember="signinMember"
            :loggedInAuthor="loggedInAuthor"
            :timelineOwner="timelineOwner"
            :timelineTargetDate="timelineTargetDate"
            :timelineTargetRecord="timelineTargetRecord"
            :timelineShouldLoad="timelineShouldLoad"
            :timelineShouldUpdate="timelineShouldUpdate"
            :narrowDownTags="narrowDownTags"
            :narrowDownAuthors="narrowDownAuthors"
            v-on:shoeQuateThreadModalEvent="showQuateThreadModal"
            v-on:showReplyEvent="showReplyRecordsModal"
            v-on:recordTagNarrowDownEvent="narrowDownByTag"
            v-on:showRecordPhotoEvent="showRecordPhoto"
            v-on:showDrugEntryPhotoEvent="showDrugEntryPhotos"
            v-on:showVideoContentEvent="showVideoContent"
            v-on:showAudioContentEvent="showAudioContent"
            v-on:addThanksEvent="addThanks"
            v-on:removeThanksEvent="removeThanks"
            v-on:nudgeRecordEvent="addNudge"
            v-on:deleteNudgeEvent="removeNudge"
            v-on:setReplyEvent="setReplyRecord"
            v-on:quoteRecordEvent="quoteRecord"
            v-on:editRecordEvent="editRecord"
            v-on:destroyRecordEvent="destroyRecord"
            v-on:timelineLoadFinishEvent="timelineLoadFinished"
            v-on:timelineUpdateFinishEvent="timelineUpdateFinished"
            v-on:scrollTopEvent="scrollToTimelineTop"
            v-on:scrollToRecordEvent="scrollToRecord"
            />
        </div><!-- normal-timeline end -->
        <horus-nudged-timeline
          v-if="timelineRecordType === 'nudge'"
          :timelineOwner="timelineOwner"
          :loggedInAuthor="loggedInAuthor"
          :nudgedRecords="nudgedRecords"
          :narrowDownTags="narrowDownTags"
          :narrowDownAuthors="narrowDownAuthors"
          :nudgeNowLoading="nudgeNowLoading"
          v-on:removeNarrowDownTagEvent="removeNarrowDownTag"
          v-on:removeNarrowDownAuthorEvent="removeNarrowDownAuthor"
          v-on:deleteNudgeEvent="removeNudge"
          v-on:showRecorPhotoEvent="showRecordPhoto"
          v-on:openRecordFileEvent="openRecordFile"
          v-on:showDrugEntryPhotoEvent="showDrugEntryPhotos"
          v-on:jumpToOrginEvent="jumpToOrigin"
          v-on:narrowDownByTagEvent="narrowDownByTag"
          />
        <!-- album timeline show -->
        <div class="album-timeline" v-show="timelineRecordType === 'album'">
          <horus-album-timeline
            v-if="timelineRecordType === 'album'"
            :timelineOwner="timelineOwner"
            :timelineTargetDate="timelineTargetDate"
            :narrowDownTags="narrowDownTags"
            :narrowDownAuthors="narrowDownAuthors"
            :timelineShouldLoad="photoShouldLoad"
            v-on:showPhotoEvent="showAlbumPhoto"
            v-on:showVideoEvent="showVideoContent"
            v-on:timelineLoadFinishEvent="photoLoadFinished"
            v-on:removeNarrowDownTagEvent="removeNarrowDownTag"
            v-on:removeNarrowDownAuthorEvent="removeNarrowDownAuthor"
            v-on:scrollAlbumEvent="scrollToAlbumPhoto"
            />
        </div>
      </div>
      <transition name="right-column">
        <div class="right-content" v-show="rightColumnOpen">
          <div class="address-book" v-show="rightColumnModeAddress">
            <horus-right-column-family-list
              :families="families"
              :loggedInUser="loggedInUser"
              :timelineOwner="timelineOwner"
              :ownTimeline="ownTimeline"
              :meetingAvailable="meetingAvailable"
              :familiesNowLoading="familiesNowLoading"
              v-on:timelineOwnerChangeEvent="moveToTargetOwnerTimeline"
              v-on:createMeetingEvent="createMeeting" />
            <horus-right-column-org-list
              :organizations="organizations"
              :organizationsNowLoading="organizationsNowLoading"
              :organizationStaffVisibilities="organizationStaffVisibilities"
              :meetingAvailable="meetingAvailable"
              v-on:createMeetingEvent="createMeeting" />
          </div>
          <div class="user-task-column" v-show="!rightColumnModeAddress">
            <horus-user-task
              :timelineOwner="timelineOwner"
              :signinMember="signinMember"
              :signinUser="loggedInUser"
              :taskUpdatedAt="taskUpdatedAt"
              v-on:userTaskUpdatedEvent="userTaskUpdated"
              v-on:createTaskEvent="createUserTask"
              v-on:editTaskEvent="editTask"
              v-on:deleteTaskEvent="deleteTask"
              v-on:showTaskDetailEvent="showTaskDetail"
              v-on:showTaskPhotoEvent="showTaskPhoto"
              v-on:showTaskConfigurationEvent="showTaskConfiguration"
              />
          </div>
        </div>
      </transition>
    </div>
    <horus-record-edit-modal
      v-if="loggedInAuthor && timelineOwner"
      :signinMember="signinMember"
      :author="loggedInAuthor"
      :timelineOwner="timelineOwner"
      :editRecord="editTargetRecord"
      :targetDate="editTargetDate"
      :selectedTags="selectedTagsForEdit"
      :createTags="createTagsForEdit"
      :pushTargets="pushTargetsForEdit"
      :medicalExamination="medicalExaminationForPost"
      :drugEntry="drugEntryForPost"
      v-on:openTagSelectionModalEvent="tagSelectionForEdit"
      v-on:openPushTargetSelectionModalEvent="pushTargetSelectionForEdit"
      v-on:removeSelectedTagEvent="removeSelectedTagFromEdit"
      v-on:removeCreateTagEvent="removeNewPostTagForEdit"
      v-on:removeSelectedAuthorEvent="removePushTargetAuthorFromEdit"
      v-on:setTargetDateForPostEvent="targetDateSelectionForEdit"
      v-on:clearTargetDateForPostEvent="clearTargetDateForEdit"
      v-on:openMedicalExaminationModalEvent="openMedicalExaminationEditModal"
      v-on:openDrugEntryModalEvent="openDrugEntryEditModal"
      v-on:postformClearEvent="confirmEditFormClear"
      v-on:showNotifyEvent="showNotifyDialog"
      v-on:submitPostFormEvent="submitUpdateForm"
      v-on:videoContentClickEvent="showVideoContent"
      v-on:audioContentClickEvent="showAudioContent"
      />
    <horus-medical-examination-edit-modal
      :medicalExamination="medicalExaminationForPost"
      :showMedicalExaminationEditModal="showMedicalExaminationEditModal"
      v-on:commitMedicalExaminationEvent="medicalExaminationCommit"
      />
    <horus-drug-entry-edit-modal
      :drugEntry="drugEntryForPost"
      :showDrugEntryEditModal="showDrugEntryEditModal"
      v-on:commitDrugEntryEvent="drugEntryCommit"
      />
    <horus-zoom-meeting-create-modal
      :loggedInUser="loggedInUser"
      :signinMember="signinMember"
      :showModal="showMeetingFormModal"
      :mainAttendee="meetingMainAttendee"
      :attendees="meetingAttendees"
      :targetTime="meetingTargetTime"
      v-on:addAttendeeEvent="addMeetingAttendee"
      v-on:removeAttendeeEvent="removeMeetingAttendee"
      v-on:setTargetTimeEvent="setMeetingTargetTime"
      v-on:changeTargetTimeEvent="setMeetingTargetTime"
      v-on:removeTargetTimeEvent="removeMeetingTargetTime"
      v-on:submitEvent="submitMeeting"
      v-on:cancelEvent="cancelMeeting"
      v-if="meetingAvailable"
      />
    <horus-user-task-form-modal
      :showModal="showUserTaskFormModal"
      :timelineOwner="timelineOwner"
      :signinUser="loggedInUser"
      :signinMember="signinMember"
      :zoomUser="zoomUser"
      :organization="null"
      :sourceTask="editTargetTask"
      :targetDate="formTaskTargetAt"
      :selectedTags="taskSelectedTags"
      :createTags="taskCreatedTags"
      :pushTargets="taskPushTargets"
      :organizationStaffVisibilities="organizationStaffVisibilities"
      v-on:setTargetDateEvent="setTaskTargetDate"
      v-on:showTaskPhotoEvent="showTaskPhoto"
      v-on:openTagSelectionModalEvent="openTagSelectionModalForTask"
      v-on:removeSelectedTagEvent="removeSelectedTagForTask"
      v-on:removeCreateTagEvent="removeCreateTagForTask"
      v-on:openPushTargetSelectionModalEvent="openPushTargetSelectionModalForTask"
      v-on:removeSelectedAuthorEvent="removeSelectedAuthorForTask"
      v-on:postformClearEvent="taskFormClear"
      v-on:submitPostFormEvent="submitTaskForm"
      v-on:showNotifyEvent="showNotifyDialog"
      />
    <horus-future-date-selection-modal
      :showDateSelectionModal="futureDateSelectionModalShow"
      :initializeDate="selectionTargetDate"
      v-on:targetDateSelectionCloseEvent="futureDateSelectionModalClose"
      v-on:selectTargetDateCancelEvent="futureDateSelectionCancel"
      v-on:selectTargetDateCommitEvent="futureDateSelectionCommit"
      />
    <horus-tag-narrow-down-modal
      :hashTags="hashTags"
      :activeTags="narrowDownTags"
      :showTagNarrowDownModal="presentedModal === PRESENTED_MODAL_TYPE.TAG_NARROW_DOWN"
      v-on:tagNarrowDownModalCloseEvent="closeTagNarrowDownModal"
      v-on:updateNarrowDownTagEvent="updateNarrowDownTag"
      v-on:sumitNarrowDownTagEvent="submitNarrowDownTags"
      v-on:clearNarrowDownTagEvent="clearNarrowDownTags"
      v-on:showTagManageEvent="tagManageModalShow" />
    <horus-author-narrow-down-modal
      :families="familiesPostable"
      :organizations="organizations"
      :organizationStaffVisibilities="organizationStaffVisibilities"
      :activeAuthors="narrowDownAuthors"
      :allFamilyActive="allFamilyNarrowDowned"
      :activeOrganizations="narrowDownOrgs"
      :showAuthorNarrowDownModal="presentedModal === PRESENTED_MODAL_TYPE.AUTHOR_NARROW_DOWN"
      v-on:authorNarrowDownModalcloseEvent="closeAuthorNarrowDownModal"
      v-on:toggleAllFamilyEvent="toggleFamilyNarrowDown"
      v-on:toggleAllMemberEvent="toggleAllMemberNarrowDown"
      v-on:toggleNarrowDownAuthor="toggleNarrowDownAuthor"
      v-on:submitNarrowDownAuthorEvent="submitNarrowDownAuthors"
      v-on:clearNarrowDownAuthorEvent="clearNarrowDownAuthors" />
    <horus-date-jump-controll
      :currentYear="new Date().getFullYear()"
      :currentMonth="new Date().getMonth()+1"
      :showDateJumpControll="presentedModal === PRESENTED_MODAL_TYPE.DATE_JUMP_CONTROLL"
      v-on:jumpDateTimelineEvent="jumpDateTimeline"
      v-on:dateJumpControlCloseEvent="closeDateJumpControll" />
    <horus-reply-records-modal
      :nudgedRecord="quatedNudgedRecord"
      :targetRecord="replyTargetRecord"
      :replyRecords="replyRecords"
      :timelineOwner="timelineOwner"
      :replyRecordsModalVisible="replyRecordsModalVisible"
      v-on:replyRecordsModalCloseEvent="closeReplyRecordsdModal"
      v-on:showRecorPhotoEvent="showRecordPhoto"
      v-on:openRecordFileEvent="openRecordFile"
      v-on:showDrugEntryPhotoEvent="showDrugEntryPhotos"
      v-on:jumpToOriginEvent="jumpToOrigin" />
    <horus-record-thread-modal
      :targetRecord="threadTargetRecord"
      :threadRecords="threadRecords"
      :timelineOwner="timelineOwner"
      :recordThreadModalVisible="recordThreadModalVisible"
      v-on:recordThreadModalCloseEvent="closeRecordThreadModal"
      v-on:showRecorPhotoEvent="showRecordPhoto"
      v-on:openRecordFileEvent="openRecordFile"
      v-on:showDrugEntryPhotoEvent="showDrugEntryPhotos"
      v-on:jumpToOriginEvent="jumpToOrigin" />
    <horus-info-content-modal
      v-if="loggedInAuthor && timelineOwner"
      :timelineOwner="timelineOwner"
      :type="modalInfoContentType"
      :showInfoContentModal="showInfoContentModal"
      v-on:infoContentModalCloseEvent="closeInfoContentModal"
      v-on:showInfoContentPhotoEvent="showInfoContentPhoto"
      v-on:newInfoContentEvent="newInfocontent"
      v-on:editInfoContentEvent="editInfoContent"
      v-on:removeInfoContentEvent="removeInfoContent"
      />
    <horus-info-content-form-modal
      v-if="loggedInAuthor && timelineOwner"
      :showInfoContentFromModal="showInfocontentFormModal"
      :timelineOwner="timelineOwner"
      :targetInfoContent="targetInfoContentForEdit"
      :type="infoContentTypeForEdit"
      :selectedTags="selectedTagsForInfoContentEdit"
      :createTags="createdTagsForInfoContentEdit"
      v-on:openTagSelectionModalEvent="tagSelectionForInfoContent"
      v-on:removeSelectedTagEvent="removeSelectedTagFromInfoContent"
      v-on:removeCreateTagEvent="removeNewPostTagForInfoContent"
      v-on:postformClearEvent="infoContentFormClear"
      v-on:showNotifyEvent="showNotifyDialog"
      v-on:submitInfoContentFormEvent="submitInfoContentForm"
      />
    <horus-video-modal
      :videoContent="videoModalContent"
      :showVideoModal="videoModalShow"
      :type="'normal'"
      v-on:closeVideoModalEvent="closeVideocontentModal"
      v-on:jumpToOriginEvent="jumpToOriginFromVideoModal"
      />
    <horus-audio-modal
      :audioContent="audioModalContent"
      :audioModalShow="audioModalShow"
      v-on:closeAudioModalEvent="closeAudioContentModal"
      />
    <horus-photo-modal
      :timelineOwner="timelineOwner"
      :photos="photoModalPhotos"
      :type="photoModalType"
      :author="photoModalAuthor"
      :targetAt="photoModalTargetAt"
      :title="photoModalTitle"
      :firstShow="photoModalFirst"
      :hashTags="photoModalHashTags"
      :showPhotoModal="photoModalShow"
      v-on:jumpToOriginEvent="jumpToOriginFromPhotoModal"
      v-on:photoModalCloseEvent="closePhotoModal"
      />
    <horus-tag-select-for-post-modal
      :showTagSelectionModal="presentedModal === PRESENTED_MODAL_TYPE.TAG_SELECTION"
      :hashTags="hashTags"
      :activeTags="selectTargetsForTag"
      :createTags="createdTargetForTag"
      :signinMember="signinMember"
      v-on:tagSelectionModalCloseEvent="tagSelectionModalClose"
      v-on:updateSelectedTagEvent="updateSelectedTag"
      v-on:addNewPostTagEvent="addNewPostTag"
      v-on:clearSelectedTagEvent="clearSelectedTags"
      v-on:removeNewTagEvent="removeNewPostTag"
      />
    <horus-author-select-for-post-modal
      :showAuthorSelectionModal="presentedModal === PRESENTED_MODAL_TYPE.PUSH_TARGET_SELECTION"
      :signinMember="signinMember"
      :families="notificatableFamilies"
      :organizations="organizations"
      :organizationStaffVisibilities="organizationStaffVisibilities"
      :selectedAuthors="selectTargetForAuthor"
      :allFamilySelected="allFamilySelectForAuthor"
      :selectLockAuthor="selectLockAuthor"
      :deselectLockAuthor="deselectLockAuthor"
      :selectedOrganizations="selectOrganizationTargetForAuthor"
      v-on:toggleAllFamilyEvent="toggleAllFamilySelect"
      v-on:toggleAllMemberEvent="toggleAllMemberSelect"
      v-on:toggleSelectAuthorEvent="toggleSelectAuthor"
      v-on:allAuthorSelectEvent="allAuthorsSelect"
      v-on:clearSelectedAuthorEvent="clearSelectedAuthors"
      v-on:authorSelectionModalCloseEvent="pushTargetSelectModalClose"
      />
    <horus-post-target-date-selection-modal
      :showDateSelectionModal="presentedModal === PRESENTED_MODAL_TYPE.POST_TARGET_DATE_SELECTION"
      :initializeDate="postTargetDate"
      v-on:targetDateSelectionCloseEvent="targetDateSelectionModalClose"
      v-on:selectTargetDateCancelEvent="targetDateSelectionCancel"
      v-on:selectTargetDateCommitEvent="targetDateSelectionCommit"
      />
    <horus-tag-manage-modal
      :timelineOwner="timelineOwner"
      :showTagManageModal="showTagManageModal"
      v-on:closeModalEvent="closeTagManageModal"
      v-on:showNotifyEvent="showNotifyDialog"
      v-on:showConfirmEvent="showConfirmDialog"
      />
    <horus-vital-display-config-modal
      :timelineOwner="timelineOwner"
      :showModal="showVitalDisplayConfig"
      v-on:vitalDisplayDataUpdatedEvent="vitalDisplayConfigUpdated"
      v-on:vitalDisplayUpdateFailEvent="vitalDisplayConfigUpdateFailed"
       />
    <horus-vital-entry-list-modal
      :timelineOwner="timelineOwner"
      :showModal="showVitalEntryList"
      v-on:closeModalEvent="vitalEntryListModalClose" />
    <horus-sending-modal
      :showSendingModal="showSendingModal"
      />
    <horus-confirm-dialog
      :showDialog="confirmDialogVisible"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :dialogType="confirmDialogType"
      v-on:negativeResultEvent="confirmFinishNegative"
      v-on:positiveResultEvent="confirmFinishPositive"
      />
    <div class="general-backdrop" v-show="showGeneralBackDrop" @click="generalModalClose"></div>
  </div>
</template>

<script>
// import moment from 'moment'

import {
  TimelineOwner,
  Author,
  // Family,
  // Organization,
  // HashTag,
  TimelineRecord,
  PhotoReport,
  FileReport,
  // DrugEntry,
  DrugEntryPhoto,
  NudgedRecord,
  VitalDisplayConfig,
  Wish,
  BoadComment
} from '@/models'

import {
  OmronConnectAccount,
  TimelineState
} from '@/api'

// header
import HorusSubBar from '@/components/navigation/HorusSubBar.vue'
import HorusSubHeader from '@/components/navigation/HorusSubHeader.vue'
// left column
import HorusLeftColumnControll from '@/components/leftColumn/HorusLeftColumnControll.vue'
import HorusLeftColumnNudgedRecord from '@/components/leftColumn/HorusLeftColumnNudgedRecord.vue'
import HorusLeftColumnWishList from '@/components/leftColumn/HorusLeftColumnWishList.vue'
import HorusLeftColumnInfoList from '@/components/leftColumn/HorusLeftColumnInfoList.vue'
import HorusVitalColumn from '@/components/vitals/HorusVitalColumn.vue'
// center column
import HorusNarrowDownHashTags from '@/components/timelineColumn/HorusNarrowDownHashTags.vue'
import HorusNarrowDownAuthors from '@/components/timelineColumn/HorusNarrowDownAuthors.vue'
import HorusNormalRecordTimeline from '@/components/timelineColumn/HorusNormalRecordTimeline.vue'
// import HorusTimelineRecord from '@/components/centerColumn/HorusTimelineRecord.vue'
import HorusNudgedTimeline from '@/components/timelineColumn/HorusNudgedTimeline.vue'
import HorusAlbumTimeline from '@/components/timelineColumn/HorusAlbumTimeline.vue'
import HorusRecordPostForm from '@/components/postForm/HorusRecordPostForm.vue'
// right column
import HorusRightColumnFamilyList from '@/components/addressBook/HorusRightColumnFamilyList.vue'
import HorusRightColumnOrgList from '@/components/addressBook/HorusRightColumnOrgList.vue'
import HorusUserTask from '@/components/userTask/HorusUserTask.vue'
// modals
import HorusRecordEditModal from '@/components/postForm/HorusRecordEditModal.vue'
import HorusTagNarrowDownModal from '@/components/leftColumn/HorusTagNarrowDownModal.vue'
import HorusAuthorNarrowDownModal from '@/components/leftColumn/HorusAuthorNarrowDownModal.vue'

import HorusDateJumpControll from '@/components/leftColumn/HorusDateJumpControll.vue'

import HorusReplyRecordsModal from '@/components/timelineRecord/HorusReplyRecordsModal.vue'
import HorusRecordThreadModal from '@/components/timelineRecord/HorusRecordThreadModal.vue'
import HorusInfoContentModal from '@/components/leftColumn/HorusInfoContentModal.vue'
import HorusPhotoModal from '@/components/common/HorusPhotoModal.vue'
import HorusTagSelectForPostModal from '@/components/postForm/HorusTagSelectForPostModal.vue' // mixin HorusTagSelection.js
import HorusAuthorSelectForPostModal from '@/components/postForm/HorusAuthorSelectForPostModal.vue' // mixin HorusAuthorSelection.js
import HorusPostTargetDateSelectionModal from '@/components/postForm/HorusPostTargetDateSelectionModal.vue'
import HorusConfirmDialog from '@/components/common/HorusConfirmDialog.vue'
import HorusInfoContentFormModal from '@/components/leftColumn/HorusInfoContentFormModal.vue'
import HorusTagManageModal from '@/components/leftColumn/HorusTagManageModal.vue'
import HorusMedicalExaminationEditModal from '@/components/postForm/HorusMedicalExaminationEditModal.vue'
import HorusDrugEntryEditModal from '@/components/postForm/HorusDrugEntryEditModal.vue'
import HorusSendingModal from '@/components/common/HorusSendingModal.vue'
import HorusVideoModal from '@/components/common/HorusVideoModal.vue'
import HorusAudioModal from '@/components/common/HorusAudioModal.vue'
import HorusVitalDisplayConfigModal from '@/components/vitals/HorusVitalDisplayConfigModal.vue'
import HorusVitalEntryListModal from '@/components/vitals/HorusVitalEntryListModal.vue'
// user task modals
import HorusUserTaskFormModal from '@/components/userTask/HorusUserTaskFormModal.vue'
import HorusFutureDateSelectionModal from '@/components/common/HorusFutureDateSelectionModal.vue'
// create meeting modal
import HorusZoomMeetingCreateModal from '@/components/zoomMeeting/HorusZoomMeetingCreateModal.vue'

import * as PRESENTED_MODAL_TYPE from '@/assets/constants/modalType.js'
import * as DIALOG_TYPE from '@/assets/constants/dialogType.js'
// const RECORD_FIRST_LOAD_COUNT = 50
// const RECORD_ADD_LOAD_COUNT = 20

import HorusConfirmDialogControll from '@/mixin/HorusConfirmDialogControll.js'
import HorusUserTaskControll from '@/mixin/HorusUserTaskControll.js'
import HorusTagSelection from '@/mixin/HorusTagSelection.js'
import HorusAuthorSelection from '@/mixin/HorusAuthorSelection.js'
import HorusTimelineOwnerRelatedData from '@/mixin/HorusTimelineOwnerRelatedData.js'
import HorusOrganizationMemberVisibility from '@/mixin/HorusOrganizationMemberVisibility.js'
import HorusFutureDateSelectionControll from '@/mixin/HorusFutureDateSelectionControll.js'
import HorusZoomMeetingControll from '@/mixin/HorusZoomMeetingControll.js'

export default {
  mixins: [
    HorusConfirmDialogControll,
    HorusUserTaskControll,
    HorusTagSelection,
    HorusAuthorSelection,
    HorusTimelineOwnerRelatedData,
    HorusOrganizationMemberVisibility,
    HorusFutureDateSelectionControll,
    HorusZoomMeetingControll
  ],

  name: 'HorusMainTimelineView',

  components: {
    HorusSubBar,
    HorusSubHeader,
    HorusLeftColumnControll,
    HorusLeftColumnNudgedRecord,
    HorusLeftColumnWishList,
    HorusLeftColumnInfoList,
    HorusNarrowDownHashTags,
    HorusNarrowDownAuthors,
    // HorusTimelineRecord,
    HorusNormalRecordTimeline,
    HorusNudgedTimeline,
    HorusAlbumTimeline,
    HorusRecordPostForm,
    HorusRightColumnFamilyList,
    HorusRightColumnOrgList,
    HorusDateJumpControll,
    HorusRecordEditModal,
    HorusTagNarrowDownModal,
    HorusAuthorNarrowDownModal,
    HorusReplyRecordsModal,
    HorusRecordThreadModal,
    HorusInfoContentModal,
    HorusPhotoModal,
    HorusTagSelectForPostModal,
    HorusAuthorSelectForPostModal,
    HorusPostTargetDateSelectionModal,
    HorusConfirmDialog,
    HorusInfoContentFormModal,
    HorusTagManageModal,
    HorusMedicalExaminationEditModal,
    HorusDrugEntryEditModal,
    HorusSendingModal,
    HorusVideoModal,
    HorusAudioModal,
    HorusUserTask,
    HorusUserTaskFormModal,
    HorusFutureDateSelectionModal,
    HorusZoomMeetingCreateModal,
    HorusVitalColumn,
    HorusVitalDisplayConfigModal,
    HorusVitalEntryListModal
  },

  props: {
    loggedInUser: Object,
    signinMember: Object,
    loggedInAuthor: Object,
    zoomUser: Object,
    taskUpdatedAt: Date
  },

  data () {
    return {
      // instantinated objects
      // loggedInAuthor: null,
      timelineOwner: null,
      families: [], // move to mixin HorusTimelineOwnerRalatedData.js
      authors: [], // move to mixin HorusTimelineOwnerRalatedData.js
      organizations: [], // move to mixin HorusTimelineOwnerRalatedData.js
      // hashTags: [],  // move to mixin HorusTimelineOwnerRalatedData.js
      wishes: [], // move to mixin HorusTimelineOwnerRalatedData.js
      invisibleWishes: [],
      infos: [], // move to mixin HorusTimelineOwnerRalatedData.js
      invisibleInfos: [],
      timelineShouldLoad: false,
      timelineShouldUpdate: false,
      photoShouldLoad: false,
      // bufferedRecords: [],
      // timelineRecords: [],
      nudgedRecords: [],
      // photoReports: [],
      // photoReportsCount: -1,
      // oldPhotoExist: false,
      // display controlls
      windowWidth: 1024,
      windowSizeNarrow: false,
      // familyTotalUnreadCount: 0,
      subHeaderVisible: false,
      leftColumnOpen: true,
      rightColumnOpen: false,
      rightColumnModeAddress: true,
      hideDateJump: false,
      // timeline narrowdown params
      narrowDownTags: [],
      familiesPostable: [],
      // notificatableFamilies: [], // move to mixin HorusAuthorSelection.js
      narrowDownAuthors: [],
      narrowDownOrgs: [],
      allFamilyNarrowDowned: false,
      // modal controll datas
      PRESENTED_MODAL_TYPE: PRESENTED_MODAL_TYPE,
      presentedModal: PRESENTED_MODAL_TYPE.NONE,
      generalBackDropTarget: null,
      generalBackDropFunction: null,
      showGeneralBackDrop: false,
      showTagManageModal: false,
      // modalInfoContents: [],
      // modalHiddenInfoContents: [],
      modalInfoContentType: '',
      showInfoContentModal: false,
      photoModalPhotos: [],
      photoModalType: '',
      photoModalAuthor: null,
      photoModalTargetAt: null,
      photoModalTitle: '',
      photoModalFirst: null,
      photoModalHashTags: [],
      photoModalShow: false,
      quatedNudgedRecord: null,
      replyTargetRecord: null,
      replyRecordsModalVisible: false,
      replyRecords: [],
      threadTargetRecord: null,
      recordThreadModalVisible: false,
      threadRecords: [],
      videoModalContent: null,
      videoModalShow: false,
      audioModalContent: null,
      audioModalShow: false,
      // timeline controll datas
      timelineRecordType: 'normal',
      targetedRecordID: null,
      timelineTargetDate: null,
      timelineTargetRecord: null,
      // latestRecordsExist: false,
      // oldRecordsExist: false,
      // loading flags
      hashTagNowLoading: true, // move to mixin HorusTimelineOwnerRalatedData.js
      authorsNowLoading: true, // move to mixin HorusTimelineOwnerRalatedData.js
      nudgeNowLoading: true,
      wishesNowLoading: true, // move to mixin HorusTimelineOwnerRalatedData.js
      infosNowLoading: true, // move to mixin HorusTimelineOwnerRalatedData.js
      familiesNowLoading: true, // move to mixin HorusTimelineOwnerRalatedData.js
      organizationsNowLoading: true, // move to mixin HorusTimelineOwnerRalatedData.js
      timelineNowLoading: true,
      // tag selection modal
      // selectTargetsForTag: [], // move to mixin HorusTagSelection.js
      // createdTargetForTag: [], // move to mixin HorusTagSelection.js
      // author selection modal
      // selectTargetForAuthor: [], // move to mixin HorusAuthorSelection.js
      // allFamilySelectForAuthor: { value: false }, // move to mixin HorusAuthorSelection.js
      // selectOrganizationTargetForAuthor: [], // move to mixin HorusAuthorSelection.js
      // date selection modal
      selectTargetDate: null,
      // post controlls
      postFormActive: false,
      referenceInfoContent: null,
      referenceInfoContentType: '',
      quatedRecordForPost: null,
      postTargetDate: { change: false, date: null },
      showMedicalExaminationEditModal: false,
      medicalExaminationForPost: {
        blood_pressure_max_value: null,
        blood_pressure_min_value: null,
        body_temperature_value: null,
        sp_o2_value: null,
        pulse_rate_value: null,
        irregular_pulse_value: -1,
        anemia_state_value: -1,
        jaundice_state_value: -1,
        oral_state_value: -1,
        respiratory_noize_value: -1,
        respiratory_rate_state_value: -1,
        hart_sound_value: -1,
        abdominal_palpation_value: -1,
        abdominal_auscultation_value: -1,
        edema_value: -1,
        bedsore_value: -1,
        eating_state_value: -1,
        take_medicine_state_value: -1,
        urination_state_value: -1,
        faces_state_value: -1
      },
      showDrugEntryEditModal: false,
      drugEntryForPost: {
        comment: '',
        photos: [],
        storedPhotos: [],
        removePhotos: []
      },
      showSendingModal: false,
      // tag selection for post
      selectedTagsForPost: [],
      createTagsForPost: [],
      // push targets for post
      pushTargetsForPost: [],
      allFamilyPush: { value: false },
      selectedOrganizationsForPush: [],
      // record edit controlls
      editTargetRecord: null,
      editTargetDate: { change: false, date: null },
      selectedTagsForEdit: [],
      createTagsForEdit: [],
      pushTargetsForEdit: [],
      allFamilyPushForEdit: { value: false },
      selectedOrganizationsForPushToEdit: [],
      // info content new/edit modal
      showInfocontentFormModal: false,
      targetInfoContentForEdit: null,
      infoContentTypeForEdit: '',
      selectedTagsForInfoContentEdit: [],
      createdTagsForInfoContentEdit: [],
      infoContentFormSuccessCallBack: () => {},
      // user task content
      taskShouldUpdate: true,
      showUserTaskFormModal: false,
      editTargetTask: null,
      formTaskTargetAt: { date: null },
      taskSelectedTags: [],
      taskCreatedTags: [],
      taskSelectedNotifyAuthors: [],
      taskAllFamilyNotify: { value: false },
      taskSelectedOrganizationsForNotify: [],
      vitalDisplayConfig: null,
      omronConnectAccountAvailable: false,
      readyToLoadVital: false,
      showVitalDisplayConfig: false,
      vitalShouldUpdate: false,
      showVitalEntryList: false
    }
  },

  computed: {
    timelineOwnerProfile () {
      if (this.timelineOwner) {
        return this.timelineOwner.birthDateJp + '生 (' + this.timelineOwner.age + '才)' + this.timelineOwner.sexTypeName
      }
      return ''
    },

    postFormAvailable () {
      if (this.authorsNowLoading || this.hashTagNowLoading || this.organizationsNowLoading) {
        return false
      }
      return true
    },

    globalStyle () {
      if (this.signinMember) {
        return 'member'
      } else {
        return ''
      }
    },

    ownTimeline () {
      if (this.loggedInUser && this.timelineOwner && this.timelineOwner.id === this.loggedInUser.id) {
        return true
      }
      return false
    },

    meetingAvailable () {
      if (process.env.VUE_APP_MEETING_AVAILABLE && this.zoomUser) {
        if (this.signinMember) {
          console.log('on maintimeline, meetingAvailable,' + this.signinMember.organization.callVideoMeetingAvailable)
          if (this.signinMember.organization.callVideoMeetingAvailable) {
            return true
          } else {
            return false
          }
        } else {
          return true
        }
      }
      return false
    },

    omronConnectAvailable () {
      if (process.env.VUE_APP_OMRON_CONNECT_AVAILABLE == 'true') {
        return true
      }
      return false
    },

    familyTotalUnreadCount () {
      var count = 0
      if (this.families) {
        this.families.forEach(family => {
          count += family.unread_count
        })
      }
      return count
    }
  },

  watch: {
    '$route': 'initTimeline',
    loggedInUser: { handler: 'initTimeline', immediate: true },
    signinMember: { handler: 'initTimeline', immediate: true },
    timelineOwner: { handler: 'initTimelineObjcts', immediate: true },
    families: { handler: 'updateFamiliesForNarrowDown', immediate: true }
  },

  mounted () {
    window.addEventListener('focus', this.handleWindowFocus)
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },

  beforeUnmount () {
    window.removeEventListener('focus', this.handleWindowFocus)
    window.removeEventListener('resize', this.handleResize)
  },

  methods: {
    handleWindowFocus () {
      // console.log('now, focus-update is ignore for debuging...')
      this.checkTimelineState()
      this.vitalShouldUpdate = true
    },

    handleResize () {
      this.windowWidth = window.innerWidth
      if (this.windowWidth > 767) {
        this.windowSizeNarrow = false
      } else {
        this.windowSizeNarrow = true
      }
    },

    // timelineを初期状態にリセットする。
    // 表示はnormal、全絞り込み及び参照の解除、左カラム表示、中央カラムフォーカス
    async resetTimeline () {
      this.clearNarrowDownTags()
      this.clearNarrowDownAuthors()
      this.clearPostForm()
      if (this.$refs.recordPostForm) {
        this.$refs.recordPostForm.initPostRecord()
      }
      this.columnOpenStateToDefault()
      this.timelineTargetDate = null
      this.timelineTargetRecord = null
      this.timelineShouldLoad = true
      this.checkTimelineState()
    },

    // timelineを初期状態にリセットし、全データをリロードする。
    // 表示はnormal、全絞り込み及び参照の解除、左カラム表示、中央カラムフォーカス
    async reloadTimeline () {
      await this.initTimelineObjcts()
    },

    async initTimeline () {
      // console.log('initTimeline call,,')
      this.resetTimeline()
      if (this.loggedInUser || this.signinMember) {
        if (this.$route.params.id) {
          await this.fetchTimelineOwner(this.$route.params.id[0])
        } else if (this.loggedInUser) {
          this.timelineOwner = this.loggedInUser
        } else if (this.signinMember) {
          // ポータルに強制送還する
          console.error('timeline owner could not get')
        }
      }
      if (this.$route.query.target_record_id) {
        this.targetedRecordID = this.$route.query.target_record_id[0]
      }
      // maintimelineの表示をデフォルトに戻す
      this.columnOpenStateToDefault()
      this.timelineShouldLoad = true
    },

    async switchTimelineOwner (ownerID) {
      // reset params
      // this.photoReports = []
      // this.photoReportsCount = -1
      this.timelineRecordType = 'normal'
      this.timelineTargetDate = null
      this.timelineTargetRecord = null
      await this.fetchTimelineOwner(ownerID)
    },

    // initialize TimelineOwner
    // 現在表示中のタイムラインのオーナーユーザーを取得して格納する。パラメータで指定されていない場合は、ログインユーザーがタイムラインオーナーとなる
    async fetchTimelineOwner (ownerID) {
      // console.log('in fetchTimelineOnwer, ' + ownerID)
      // console.log(ownerID)
      var owner = await TimelineOwner.find(ownerID)
      if (owner === null) {
        await TimelineOwner.api().fetch({ timelineOwnerID: ownerID }).then(() => {
          this.timelineOwner = TimelineOwner.find(ownerID)
        })
      } else {
        this.timelineOwner = owner
      }
      if (!this.timelineOwner.autor) {
        await Author.api().fetchByID({ timelineOwnerID: this.timelineOwner.id, idList: [this.timelineOwner.author_id] })
      }
    },

    // オブジェクトを初期化する
    async initTimelineObjcts () {
      // console.log('init timeline objects')
      await this.resetTimeline()
      this.timelineRecordType = 'normal'
      this.timelineTargetDate = null
      this.timelineTargetRecord = null
      this.bufferedRecords = []
      if (this.timelineOwner) {
        if ((this.loggedInUser && (this.timelineOwner.id === this.loggedInUser.id)) || (this.loggedInAuthor && (this.timelineOwner.id === this.loggedInAuthor.rawID))) {
          this.subHeaderVisible = false
        } else {
          this.subHeaderVisible = true
        }
        // load authors とりあえず取得して格納
        await this.loadTimelineAuthors()
        this.loadFamilies()
        this.loadOrganizations()
        this.loadTimelineHashTags()
        this.loadWishes()
        this.loadInfos()
        // await this.loadTimelineRecords()
        this.loadNudgeRecords()
        this.loadMissingAuthors()
        // this.timelineShouldLoad = true
        // loadVitals
        this.loadVitalConfigs()
      }
    },

    async loadVitalConfigs () {
      if (this.timelineOwner) {
        await this.checkOmronConnectAvailable()
        // if (this.omronConnectAccountAvailable) {
        //   OmronConnectAccount.updateVitals({ targetUserID: this.timelineOwner.id }).then(res => {
        //     console.log('update success...')
        //     console.log(res)
        //   }).catch(error => {
        //     console.error(error)
        //   })
        // } else {
        //   this.nowLoading = false
        // }
      }
    },

    async checkOmronConnectAvailable () {
      if (process.env.VUE_APP_OMRON_CONNECT_AVAILABLE === 'true') {
        // fetch omron_connect_account status
        OmronConnectAccount.fetch({ targetUserID: this.timelineOwner.id }).then(res => {
          console.log('omron connect account available')
          console.log(res)
          if (res.omronConnectAccount) {
            this.omronConnectAccountAvailable = true
            this.loadVitalDisplayConfig()
          }
        })
      } else {
        this.omronConnectAccountAvailable = false
      }
    },

    loadVitalDisplayConfig () {
      this.vitalDisplayConfig = VitalDisplayConfig.query().where('timelineOnwerID', this.timelineOwner.id).first()
      if (!this.vitalDisplayConfig) {
        VitalDisplayConfig.api().fetch({ targetUserID: this.timelineOwner.id }).then(() => {
          this.vitalDisplayConfig = VitalDisplayConfig.query().where('timelineOnwerID', this.timelineOwner.id).first()
        })
      }
    },

    vitalDisplayConfigShow () {
      this.showVitalDisplayConfig = true
    },

    vitalDisplayConfigUpdated () {
      this.showVitalDisplayConfig = false
      VitalDisplayConfig.api().fetch({ targetUserID: this.timelineOwner.id }).then(() => {
        this.vitalDisplayConfig = VitalDisplayConfig.query().where('timelineOnwerID', this.timelineOwner.id).first()
        console.log('vital display config reloaded....')
        this.vitalShouldUpdate = true
      })
    },

    vitalDisplayConfigUpdateFailed () {
      this.showVitalDisplayConfig = false
      this.showNotifyDialog('バイタル表示設定更新失敗', 'バイタル表示設定の更新に失敗しました。')
    },

    vitalUpdated () {
      this.vitalShouldUpdate = false
    },

    vitalForceReload (executeReload) {
      this.showConfirmDialog(
        'バイタルデータの強制リロード',
        'バイタルデータの強制リロードを行います。\nこの操作はサーバーに負荷がかかる操作です。連続して実行しないようにしてください。\n実行する場合は「はい」を押してください。',
        DIALOG_TYPE.YES_NO,
        executeReload
      )
    },

    vitalEntryListShow () {
      console.log('in timeline, show vital entry modal.....')
      this.showVitalEntryList = true
    },

    vitalEntryListModalClose () {
      this.showVitalEntryList = false
    },

    // chanc of loaders
    // タイムラインオーナーに関連付いているユーザー、施設職員をauthorとして読み込む
    // move to mixin/HorusTimelineOwnerRalatedData.js
    async loadTimelineAuthors () {
      await this.loadAuthors()
      this.narrowDownAuthors = []
      this.narrowDownOrgs = []
    },

    // サーバーからタイムラインオーナーに関連付いたハッシュタグを取得し、storeに格納(更新)し、this.hashTagsに格納する
    // move to mixin/HorusTimelineOwnerRalatedData.js
    async loadTimelineHashTags () {
      await this.loadHashTags()
      this.narrowDownTags = []
    },

    // familyPostableに書き込み可能な家族Authorを格納する
    updateFamiliesForNarrowDown () {
      var fams = []
      fams = fams.concat(this.families.filter(family => family.relation < 3))
      var famAuthors = []
      var famNotify = []
      if (this.timelineOwner) {
        famAuthors.push(this.timelineOwner.author)
        if (this.signinMember || (this.loggedInUser && this.loggedInUser.id !== this.timelineOwner.id)) {
          famNotify.push(this.timelineOwner.author)
        }
      }
      fams.forEach(family => {
        famAuthors.push(family.author)
        famNotify.push(family.author)
      })
      this.familiesPostable = famAuthors
      this.notificatableFamilies = famNotify
    },

    timelineLoadFinished () {
      this.timelineTargetDate = null
      this.timelineTargetRecord = null
      this.timelineShouldLoad = false
    },

    timelineUpdateFinished (addedRecords, updatedRecords) {
      this.timelineShouldUpdate = false
      // highlight?
      addedRecords.forEach(aRec => {
        this.highlightRecordBlock(aRec)
      })
      updatedRecords.forEach(uRec => {
        this.highlightRecordBlock(uRec)
      })
    },

    photoLoadFinished () {
      this.timelineTargetDate = null
      this.timelineTargetRecord = null
      this.photoShouldLoad = false
    },

    // ナッジレコードを全件取得し、storeに格納、nudgedRecordsに格納する
    async loadNudgeRecords () {
      this.nudgeNowLoading = true
      await NudgedRecord.api().fetch({ timelineOwnerID: this.timelineOwner.id })
      var nudgedRecords = await NudgedRecord.query().where('user_id', this.timelineOwner.id).get()
      await this.loadNudgedTargets(nudgedRecords)
      this.nudgedRecords = await NudgedRecord.query().where('user_id', this.timelineOwner.id).orderBy('created_at', 'desc').get()
      this.nudgeNowLoading = false
    },

    // nudgedRecordに関連付いているがロードされていないレコードをstoreに格納する
    async loadNudgedTargets (nudgedRecords) {
      var list = []
      for(var nudge of nudgedRecords) {
        list.push(nudge.record_id)
      }
      await this.storeTimelineRecords(list)
    },

    // 引数配列で渡されたID配列のレコードのうち、storeに格納されていないものをサーバーから取得し、storeに格納する
    async storeTimelineRecords (recordIDList) {
      var unStoredIDList = []
      for(var recordID of recordIDList) {
        
        if (!TimelineRecord.find(recordID)) {
          if (unStoredIDList.indexOf(recordID) < 0) unStoredIDList.push(recordID)
        }
      }
      if (unStoredIDList.length > 0) {
        return TimelineRecord.api().fetchByID({ timelineOwnerID: this.timelineOwner.id, idList: unStoredIDList })
      } else {
        return unStoredIDList
      }
    },

    // wish, info, nudgedRecord, timelineRecordに関連付いていながら、まだロードされていないauthorを取得し、storeに格納する。
    // ユーザーがすでに削除されていたり、家族関連を断っていたり、かかりつけ施設から外れていた場合に発生する。
    async loadMissingAuthors () {
      var missingAuthorIDList = []
      for(var wish of this.wishes) {
        if (!wish.author) {
          if (missingAuthorIDList.indexOf(wish.author_id) < 0) missingAuthorIDList.push(wish.author_id)
        }
      }
      for(var info of this.infos) {
        if (!info.author) {
          if (missingAuthorIDList.indexOf(info.author_id) < 0) missingAuthorIDList.push(info.author_id)
        }
      }
      for(var nudge of this.nudgedRecords) {
        if (!nudge.author) {
          if (missingAuthorIDList.indexOf(nudge.author_id) < 0) missingAuthorIDList.push(nudge.executer_id)
        }
      }
      for(var rec of this.bufferedRecords) {
        if (!rec.author) {
          if (missingAuthorIDList.indexOf(rec.author_id) < 0) missingAuthorIDList.push(rec.author_id)
        }
      }
      if (missingAuthorIDList.length > 0) {
        await Author.api().fetchByID({ timelineOwnerID: this.timelineOwner.id, idList: missingAuthorIDList })
      }
    },

    // 引数レコードに返信している(引数レコードを引用している)レコードを取得し、this.replyRecordsに格納する。
    async loadReplyRecords (targetRecord) {
      await TimelineRecord.api().fetchChildrenRecordIDList({ timelineOwnerID: this.timelineOwner.id, targetRecordID: targetRecord.id }).then(async res => {
        await this.storeTimelineRecords(res.recordIDList)
        this.replyRecords = TimelineRecord.findIn(res.recordIDList)
      })
    },

    // 引数レコードが引用しているレコードを、引用元がなくなるまで取り出して格納する。
    async loadThreadRecords (targetRecord) {
      await TimelineRecord.api().fetchThreadRecordIDList({ timelineOwnerID: this.timelineOwner.id, targetRecordID: targetRecord.id }).then(async res => {
        await this.storeTimelineRecords(res.recordIDList)
        this.threadRecords = TimelineRecord.findIn(res.recordIDList)
      })
    },

    // サーバー側で識別している最終ロードからの更新状況を取得し、更新が必要な項目は取得してstoreを更新する。
    async checkTimelineState () {
      if (this.timelineOwner) {
        await TimelineState.fetch({ timelineOwnerID: this.timelineOwner.id }).then(async res => {
          var authorNeedCheck = false
          if (res.need_check_organizations && res.need_check_organizations.length > 0) {
            authorNeedCheck = true
            this.updateOrganization(res.need_check_organizations)
          } else if (res.organization_list_need_check) {
            authorNeedCheck = true
            await this.loadOrganizations()
          }
          if (authorNeedCheck || res.family_need_check) {
            await this.loadTimelineAuthors()
          }
          if (res.family_need_check) {
            await this.loadFamilies()
          }
          if (res.tag_need_check) {
            await this.loadTimelineHashTags()
          }
          if (res.nudge_need_check) {
            await this.loadNudgeRecords()
          }
          if (res.wish_need_check) {
            await this.loadWishes()
          }
          if (res.info_need_check) {
            await this.loadInfos()
          }
          this.timelineShouldUpdate = true
        })
      }
    },

    // column style controll
    toggleLeftColumn () {
      this.leftColumnOpen = !this.leftColumnOpen
      if (this.rightColumnOpen) {
        this.rightColumnOpen = false
      }
    },

    toggleRightColumn () {
      this.rightColumnOpen = !this.rightColumnOpen
      if (this.leftColumnOpen) {
        this.leftColumnOpen = false
      }
    },

    toggleAddressColumn () {
      if (this.rightColumnOpen) {
        if (!this.rightColumnModeAddress) {
          this.rightColumnModeAddress = true
        } else {
          this.toggleRightColumn()
        }
      } else {
        this.rightColumnModeAddress = true
        this.toggleRightColumn()
      }
    },

    toggleTaskColumn () {
      console.log('toggle task call,')
      if (this.rightColumnOpen) {
        if (this.rightColumnModeAddress) {
          this.rightColumnModeAddress = false
        } else {
          this.toggleRightColumn()
        }
      } else {
        // this.rightColumnModeAddress = false
        this.rightColumnModeAddress = false
        this.toggleRightColumn()
      }
    },

    columnOpenStateToDefault () {
      if (this.windowSizeNarrow) {
        this.leftColumnOpen = false
        this.rightColumnOpen = false
      } else {
        this.leftColumnOpen = true
        this.rightColumnOpen = false
      }
    },

    focusCenterColumn () {
      if (this.windowSizeNarrow) {
        this.leftColumnOpen = false
        this.rightColumnOpen = false
      }
    },

    // modal controlls
    openTagNarrowDownModal () {
      this.clearNarrowDownAuthors()
      this.presentedModal = PRESENTED_MODAL_TYPE.TAG_NARROW_DOWN
    },

    closeTagNarrowDownModal () {
      this.presentedModal = PRESENTED_MODAL_TYPE.NONE
      if (this.timelineRecordType === 'normal') {
        this.timelineShouldLoad = true
      } else if (this.timelineRecordType === 'album') {
        this.photoShouldLoad = true
      }
    },

    openAuthorNarrowDownModal () {
      this.clearNarrowDownTags()
      this.presentedModal = PRESENTED_MODAL_TYPE.AUTHOR_NARROW_DOWN
    },

    closeAuthorNarrowDownModal () {
      this.presentedModal = PRESENTED_MODAL_TYPE.NONE
      if (this.timelineRecordType === 'normal') {
        this.timelineShouldLoad = true
      } else if (this.timelineRecordType === 'album') {
        this.photoShouldLoad = true
      }
    },

    openDateJumpControll () {
      this.presentedModal = PRESENTED_MODAL_TYPE.DATE_JUMP_CONTROLL
    },

    closeDateJumpControll () {
      this.presentedModal = PRESENTED_MODAL_TYPE.NONE
    },

    tagSelectionForPost () {
      this.selectTargetsForTag = this.selectedTagsForPost
      this.createdTargetForTag = this.createTagsForPost
      this.tagSelectionModalOpen()
    },

    tagSelectionForEdit () {
      this.selectTargetsForTag = this.selectedTagsForEdit
      this.createdTargetForTag = this.createTagsForEdit
      this.tagSelectionModalOpen()
    },

    tagSelectionModalOpen () {
      this.presentedModal = PRESENTED_MODAL_TYPE.TAG_SELECTION
    },

    pushTargetSelectionForPost () {
      this.selectTargetForAuthor = this.pushTargetsForPost
      this.allFamilySelectForAuthor = this.allFamilyPush
      this.selectOrganizationTargetForAuthor = this.selectedOrganizationsForPush
      this.pushTargetSelectModalOpen()
    },

    pushTargetSelectionForEdit () {
      this.selectTargetForAuthor = this.pushTargetsForEdit
      this.allFamilySelectForAuthor = this.allFamilyPushForEdit
      this.selectOrganizationTargetForAuthor = this.selectedOrganizationsForPushToEdit
      this.pushTargetSelectModalOpen()
    },

    pushTargetSelectModalOpen () {
      this.presentedModal = PRESENTED_MODAL_TYPE.PUSH_TARGET_SELECTION
    },

    targetDateSelectionForPost () {
      this.selectTargetDate = this.postTargetDate
      this.targetDateSelectionModalOpen()
    },

    targetDateSelectionForEdit () {
      this.selectTargetDate = this.editTargetDate
      this.targetDateSelectionModalOpen()
    },

    targetDateSelectionModalOpen () {
      this.presentedModal = PRESENTED_MODAL_TYPE.POST_TARGET_DATE_SELECTION
    },

    targetDateSelectionModalClose () {
      this.presentedModal = PRESENTED_MODAL_TYPE.NONE
    },

    // record thread modal controlls
    showNudgedRecordModal (nudgedRecord) {
      this.quatedNudgedRecord = nudgedRecord
      this.replyTargetRecord = nudgedRecord.record
      this.loadReplyRecords(this.replyTargetRecord)
      this.replyRecordsModalVisible = true
    },

    showReplyRecordsModal (timelineRecord) {
      this.quatedNudgedRecord = null
      this.replyTargetRecord = timelineRecord
      this.loadReplyRecords(this.replyTargetRecord)
      this.replyRecordsModalVisible = true
    },

    closeReplyRecordsdModal () {
      this.quatedNudgedRecord = null
      this.replyTargetRecord = null
      this.replyRecordsModalVisible = false
      this.replyRecords = []
    },

    showQuateThreadModal (timelineRecord) {
      this.threadTargetRecord = timelineRecord
      this.loadThreadRecords(this.threadTargetRecord)
      this.recordThreadModalVisible = true
    },

    closeRecordThreadModal () {
      this.recordThreadModalVisible = false
      this.threadTargetRecord = null
      this.threadRecords = []
    },

    generalModalClose (event) {
      if (this.generalBackDropTarget) {
        const clickX = event.pageX
        const clickY = event.pageY
        const targetRect = this.generalBackDropTarget.getBoundingClientRect()
        if (clickX > targetRect.x && clickX < targetRect.x + targetRect.width && clickY > targetRect.y && clickY < targetRect.y + targetRect.height) {
          const backElms = document.elementsFromPoint(clickX, clickY)
          backElms[1].click()
          event.preventDefault()
        } else {
          this.generalBackDropFunction()
          this.showGeneralBackDrop = false
          this.generalBackDropTarget = null
          this.generalBackDropFunction = null
        }
      } else {
        this.showGeneralBackDrop = false
        this.generalBackDropTarget = null
        this.generalBackDropFunction = null
      }
    },

    // photo modal controlls
    showRecordPhoto (timelineRecord, firstShowIndex) {
      if (timelineRecord.photoReports.length > 0) {
        if (!firstShowIndex || firstShowIndex > timelineRecord.photoReports.length - 1) {
          firstShowIndex = 0
        }
      }
      this.photoModalPhotos = timelineRecord.photoReports
      this.photoModalAuthor = timelineRecord.author
      this.photoModalTargetAt = timelineRecord.targetAt
      this.photoModalType = 'photoReport'
      this.photoModalTitle = '投稿写真'
      this.photoModalFirst = this.photoModalPhotos[firstShowIndex]
      this.photoModalShow = true
    },

    showDrugEntryPhotos (timelineRecord, firstShowIndex) {
      if (timelineRecord.drugEntry && timelineRecord.drugEntry.drugEntryPhotos) {
        if (!firstShowIndex || firstShowIndex > timelineRecord.drugEntry.drugEntryPhotos.length - 1) {
          firstShowIndex = 0
        }
      }
      this.photoModalPhotos = timelineRecord.drugEntry.drugEntryPhotos
      this.photoModalAuthor = timelineRecord.author
      this.photoModalTargetAt = timelineRecord.targetAt
      this.photoModalType = 'drugEntry'
      this.photoModalTitle = 'お薬情報写真'
      this.photoModalFirst = this.photoModalPhotos[firstShowIndex]
      this.photoModalShow = true
    },

    showInfoContentPhoto (infoContent, type, firstShowIndex) {
      if (infoContent.photos.length > 0) {
        if (!firstShowIndex || firstShowIndex > infoContent.photos.length - 1) {
          firstShowIndex = 0
        }
      }
      this.photoModalPhotos = infoContent.photos
      this.photoModalAuthor = infoContent.author
      this.photoModalTargetAt = infoContent.updatedAt
      this.photoModalType = type
      if (type === 'boadComment') {
        this.photoModalTitle = 'info'
      } else if (type === 'wish') {
        this.photoModalTitle = 'wish'
      }
      this.photoModalFirst = this.photoModalPhotos[firstShowIndex]
      this.photoModalShow = true
    },

    showAlbumPhoto (photoReport) {
      this.photoModalPhotos = [photoReport]
      this.photoModalAuthor = photoReport.timelineRecord.author
      this.photoModalTargetAt = photoReport.timelineRecord.targetAt
      this.photoModalType = 'album'
      this.photoModalTitle = '投稿写真'
      this.photoModalFirst = photoReport
      this.photoModalHashTags = photoReport.timelineRecord.hashTags
      this.photoModalShow = true
    },

    closePhotoModal () {
      this.photoModalPhotos = []
      this.photoModalType = ''
      this.photoModalAuthor = null
      this.photoModalTargetAt = null
      this.photoModalFirst = null
      this.photoModalTitle = ''
      this.photoModalHashTags = []
      this.photoModalShow = false
    },

    showVideoContent (videoContent) {
      this.videoModalContent = videoContent
      this.videoModalShow = true
    },

    closeVideocontentModal () {
      this.videoModalContent = null
      this.videoModalShow = false
    },

    showAudioContent (audioContent) {
      this.audioModalContent = audioContent
      this.audioModalShow = true
    },

    closeAudioContentModal () {
      this.audioModalContent = null
      this.audioModalShow = false
    },

    // info content modal controlls
    openWishModal () {
      this.modalInfoContentType = 'wish'
      this.showInfoContentModal = true
    },

    openBoadCommentModal () {
      this.modalInfoContentType = 'boadComment'
      this.showInfoContentModal = true
    },

    closeInfoContentModal () {
      this.showInfoContentModal = false
      this.modalInfoContents = []
      this.modalHiddenInfoContents = []
      this.modalInfoContentType = ''
      this.checkTimelineState()
    },

    newInfocontent (type, callBack = () => {}) {
      this.targetInfoContentForEdit = null
      this.infoContentTypeForEdit = type
      this.selectedTagsForInfoContentEdit = []
      this.createdTagsForInfoContentEdit = []
      this.infoContentFormSuccessCallBack = callBack
      this.showInfocontentFormModal = true
    },

    editInfoContent (infoContent, type, callBack) {
      this.targetInfoContentForEdit = infoContent
      this.infoContentTypeForEdit = type
      this.selectedTagsForInfoContentEdit = infoContent.hashTags
      this.createdTagsForInfoContentEdit = []
      this.infoContentFormSuccessCallBack = callBack
      this.showInfocontentFormModal = true
    },

    tagSelectionForInfoContent () {
      this.selectTargetsForTag = this.selectedTagsForInfoContentEdit
      this.createdTargetForTag = this.createdTagsForInfoContentEdit
      this.tagSelectionModalOpen()
    },

    removeSelectedTagFromInfoContent (tag) {
      var tagIndex = this.selectedTagsForInfoContentEdit.indexOf(tag)
      if (tagIndex >= 0) {
        this.selectedTagsForPost.splice(tagIndex, 1)
      }
    },

    removeNewPostTagForInfoContent (index) {
      this.createdTagsForInfoContentEdit.splice(index, 1)
    },

    clearInfoContentForm () {
      this.targetInfoContentForEdit = null
      this.infoContentTypeForEdit = ''
      this.selectedTagsForInfoContentEdit = []
      this.createdTagsForInfoContentEdit = []
      this.infoContentFormSuccessCallBack = () => {}
      this.showInfocontentFormModal = false
    },

    infoContentFormClear (callBack) {
      var titleMessage = ''
      var contentMessage = ''
      if (this.targetInfoContentForEdit) {
        if (this.infoContentTypeForEdit === 'wish') {
          titleMessage = 'wish編集の中止'
          contentMessage = 'wishの編集を中止しようとしています。\n変更の内容は全て破棄されます。\n中止しますか?'
        } else if (this.infoContentTypeForEdit === 'boadComment') {
          titleMessage = 'info編集の中止'
          contentMessage = 'infoの編集を中止しようとしています。\n変更の内容は全て破棄されます。\n中止しますか?'
        }
      } else {
        if (this.infoContentTypeForEdit === 'wish') {
          titleMessage = 'wish新規作成の中止'
          contentMessage = 'wishの新規作成を中止しようとしています。\n入力した内容は全て破棄されます。\n中止しますか?'
        } else if (this.infoContentTypeForEdit === 'boadComment') {
          titleMessage = 'info新規作成の中止'
          contentMessage = 'infoの新規作成を中止しようとしています。\n入力した内容は全て破棄されます。\n中止しますか?'
        }
      }
      this.showConfirmDialog(
        titleMessage,
        contentMessage,
        DIALOG_TYPE.YES_NO,
        () => {
          this.clearInfoContentForm()
          callBack()
        }
      )
    },

    async submitInfoContentForm (formData, successCallBack) {
      this.showSendingModal = true
      if (this.targetInfoContentForEdit) {
        if (this.infoContentTypeForEdit === 'wish') {
          await Wish.api().update({ formData: formData }).then(async () => {
            await successCallBack()
            await this.loadTimelineHashTags()
            await this.loadWishes()
            this.infoContentFormSuccessCallBack()
            this.clearInfoContentForm()
            this.showSendingModal = false
          }).catch(error => {
            console.error(error.response)
            this.showSendingModal = false
            this.showNotifyDialog('wish更新失敗', 'wishの更新に失敗しました。')
          })
        } else if (this.infoContentTypeForEdit === 'boadComment') {
          await BoadComment.api().update({ formData: formData }).then(async () => {
            await successCallBack()
            await this.loadTimelineHashTags()
            await this.loadInfos()
            this.infoContentFormSuccessCallBack()
            this.clearInfoContentForm()
            this.showSendingModal = false
          }).catch(error => {
            console.error(error.response)
            this.showSendingModal = false
            this.showNotifyDialog('info更新失敗', 'infoの更新に失敗しました。')
          })
        }
      } else {
        if (this.infoContentTypeForEdit === 'wish') {
          await Wish.api().create({ formData: formData }).then(async () => {
            await successCallBack()
            await this.loadTimelineHashTags()
            await this.loadWishes()
            this.infoContentFormSuccessCallBack()
            this.clearInfoContentForm()
            this.showSendingModal = false
          }).catch(error => {
            console.error(error.response)
            this.showSendingModal = false
            this.showNotifyDialog('wish新規作成失敗', '新規wishの送信に失敗しました。')
          })
        } else if (this.infoContentTypeForEdit === 'boadComment') {
          await BoadComment.api().create({ formData: formData }).then(async () => {
            await successCallBack()
            await this.loadTimelineHashTags()
            await this.loadInfos()
            this.infoContentFormSuccessCallBack()
            this.clearInfoContentForm()
            this.showSendingModal = false
          }).catch(error => {
            console.error(error.response)
            this.showSendingModal = false
            this.showNotifyDialog('info新規作成失敗', '新規infoの送信に失敗しました。')
          })
        }
      }
    },

    removeInfoContent (infoContent, type, callBack) {
      var typeName = ''
      if (type === 'wish') {
        typeName = 'wish'
      } else if (type === 'boadComment') {
        typeName = 'info'
      }
      this.showConfirmDialog(
        typeName + 'の削除',
        typeName + 'を削除しようとしています。\n削除してよろしいですか?',
        DIALOG_TYPE.YES_NO,
        callBack
      )
    },

    // tag manage modal
    tagManageModalShow () {
      this.showTagManageModal = true
    },

    async closeTagManageModal () {
      await this.loadTimelineHashTags()
      this.showTagManageModal = false
    },

    // timeline controlls
    // tag narrow downs
    addNarrowDownTag (tag) {
      if (this.narrowDownTags.indexOf(tag) < 0) {
        this.narrowDownTags.push(tag)
      }
    },

    updateNarrowDownTag (tag, currentActive, currentEnable) {
      if (currentEnable) {
        if (currentActive) {
          var tagIndex = this.narrowDownTags.indexOf(tag)
          if (tagIndex >= 0) {
            this.narrowDownTags.splice(tagIndex, 1)
          }
        } else {
          this.narrowDownTags.push(tag)
          // this.clearNarrowDownAuthors()
        }
      } else {
        this.narrowDownTags.push(tag)
        // this.clearNarrowDownAuthors()
      }
    },

    selectSurrogateTagNarrowDown () {
      var tag = this.hashTags.filter(tag => tag.tagName === '本人##本人曰く')[0]
      this.addNarrowDownTag(tag)
    },

    deselectSurrogateTagNarrowDown () {
      var tag = this.hashTags.filter(tag => tag.tagName === '本人##本人曰く')[0]
      var tagIndex = this.narrowDownTags.indexOf(tag)
      if (tagIndex >= 0) {
        this.narrowDownTags.splice(tagIndex, 1)
      }
    },

    submitNarrowDownTags () {
      this.focusCenterColumn()
    },

    clearNarrowDownTags () {
      this.narrowDownTags = []
      this.focusCenterColumn()
    },

    removeNarrowDownTag (tag) {
      const tagIndex = this.narrowDownTags.indexOf(tag)
      if (tagIndex >= 0) {
        this.narrowDownTags.splice(tagIndex, 1)
        if (this.timelineRecordType === 'normal') {
          this.timelineShouldLoad = true
        } else if (this.timelineRecordType === 'album') {
          this.photoShouldLoad = true
        }
      }
    },

    // author narrow down controlls
    toggleFamilyNarrowDown () {
      if (this.allFamilyNarrowDowned) {
        this.familiesPostable.forEach(family => {
          this.removeNarrowDownAuthor(family)
          this.allFamilyNarrowDowned = false
        })
      } else {
        this.familiesPostable.forEach(family => {
          this.addNarrowDownAuthor(family)
          this.allFamilyNarrowDowned = true
        })
      }
    },

    toggleAllMemberNarrowDown (organization) {
      const orgIndex = this.narrowDownOrgs.indexOf(organization.id)
      if (orgIndex >= 0) {
        this.organizationVisibleMembers(organization).forEach(member => {
          this.removeNarrowDownAuthor(member)
        })
        this.narrowDownOrgs.splice(orgIndex, 1)
      } else {
        this.organizationVisibleMembers(organization).forEach(member => {
          this.addNarrowDownAuthor(member)
        })
        this.narrowDownOrgs.push(organization.id)
      }
    },

    toggleNarrowDownAuthor (author) {
      const authorIndex = this.narrowDownAuthors.indexOf(author)
      if (authorIndex >= 0) {
        this.removeNarrowDownAuthor(author, authorIndex)
      } else {
        this.addNarrowDownAuthor(author)
      }
    },

    addNarrowDownAuthor (author) {
      if (!this.narrowDownAuthors.some(na => na.id === author.id)) {
        this.narrowDownAuthors.push(author)
      }
      this.updateNarrowDownGroupState(author)
    },

    removeNarrowDownAuthor (author, index) {
      var authorIndex = index
      if (!index >= 0) {
        authorIndex = this.narrowDownAuthors.indexOf(author)
      }
      if (authorIndex >= 0) {
        this.narrowDownAuthors.splice(authorIndex, 1)
        if (this.timelineRecordType === 'normal') {
          this.timelineShouldLoad = true
        } else if (this.timelineRecordType === 'album') {
          this.photoShouldLoad = true
        }
      }
      this.updateNarrowDownGroupState(author)
    },

    // author narrow downのチェックボックス状態の整合性を取る
    // MEMO: author一人変更する旅にチェックするのだるくね?
    updateNarrowDownGroupState (author) {
      var allActive = true
      if (author.organization) {
        author.organization.members.forEach(member => {
          if (!this.narrowDownAuthors.some(author => author.id === member.id)) {
            allActive = false
          }
        })
        var orgIndex = this.narrowDownOrgs.indexOf(author.organization.id)
        if (allActive) {
          if (orgIndex < 0) {
            this.narrowDownOrgs.push(author.organization.id)
          }
        } else {
          if (orgIndex >= 0) {
            this.narrowDownOrgs.splice(orgIndex, 1)
          }
        }
      } else {
        this.familiesPostable.forEach(family => {
          if (!this.narrowDownAuthors.some(author => author.id === family.id)) {
            allActive = false
          }
        })
        this.allFamilyNarrowDowned = allActive
      }
      if (this.narrowDownAuthors.length > 0) {
        // this.clearNarrowDownTags()
        // NOTE: もしも絞り込み著者に本人が含まれているなら、「本人曰く」タグを追加する
        if (this.narrowDownAuthors.some(author => author.id === this.timelineOwner.author_id)) {
          this.selectSurrogateTagNarrowDown()
        } else {
          this.deselectSurrogateTagNarrowDown()
        }
      } else {
        this.deselectSurrogateTagNarrowDown()
      }
    },

    submitNarrowDownAuthors () {
      this.focusCenterColumn()
    },

    clearNarrowDownAuthors () {
      this.narrowDownAuthors = []
      this.allFamilyNarrowDowned = false
      this.narrowDownOrgs = []
      // 本人曰くタグを削除する
      this.deselectSurrogateTagNarrowDown()
      this.focusCenterColumn()
    },

    narrowDownByTag (tag) {
      this.narrowDownTags = [tag]
      this.narrowDownAuthors = []
      if (this.timelineRecordType === 'normal') {
        this.timelineShouldLoad = true
      } else if (this.timelineRecordType === 'album') {
        this.photoShouldLoad = true
      }
    },

    switchNudgedTimeline () {
      this.timelineRecordType = 'nudge'
      this.focusCenterColumn()
    },

    switchTimeline (timelineType) {
      console.log('switchTimeline call, ' + timelineType)
      this.timelineRecordType = timelineType
      if (timelineType === 'nudge') {
        this.switchNudgedTimeline()
      } else if (timelineType === 'album') {
        console.log('show album timeline')
        this.timelineTargetDate = null
        this.timelineShouldLoad = false
        this.photoShouldLoad = true
      } else {
        console.log('show normal timeline')
        this.timelineRecordType = 'normal'
        this.resetTimeline()
        this.photoShouldLoad = false
        this.timelineShouldLoad = true
        this.$nextTick(() => {
          const centerContent = this.$refs.mainTimeline
          centerContent.scrollTop = 0
        })
      }
      this.focusCenterColumn()
    },

    updateTimeline () {
      console.log('updateTimeline')
      this.reloadTimeline()
    },

    jumpToOriginFromPhotoModal (photoReport) {
      this.closePhotoModal()
      this.jumpToOrigin(photoReport.timelineRecord)
    },

    jumpToOriginFromVideoModal (record) {
      this.closeVideocontentModal()
      this.jumpToOrigin(record)
    },

    jumpToOrigin (record) {
      this.timelineRecordType = 'normal'
      this.narrowDownTags = []
      this.narrowDownAuthors = []
      // タイムラインの読み直しはNormalRecordTimelineに任せる。
      this.timelineTargetDate = null
      this.timelineTargetRecord = record
      this.focusCenterColumn()
      this.timelineShouldLoad = true
    },

    jumpDateTimeline (year, month, date) {
      // タイムラインの読み直しはNormalRecordTimelineに任せる。
      this.timelineTargetRecord = null
      this.timelineTargetDate = new Date(year, month - 1, date, 23, 59)
      if (this.timelineRecordType === 'normal') {
        this.timelineShouldLoad = true
      } else if (this.timelineRecordType === 'album') {
        this.photoShouldLoad = true
      }
      this.focusCenterColumn()
    },

    scrollToRecord (record, offset) {
      // console.log('scrollToRecord call,')
      // console.log(record)
      // console.log(offset)
      if (record) {
        // console.log('record exist.....')
        var offsetHeight = offset || -110
        if (this.signinMember) {
          offsetHeight = -130
        }
        // console.log(offsetHeight)
        this.highlightRecordBlock(record)
        this.$nextTick(() => {
          const centerContent = this.$refs.mainTimeline
          const timelineInner = centerContent.querySelector('#mainTimelineInner')
          const targetElm = centerContent.querySelector('#timelineRecord' + record.id)
          // targetElm.style.backgroundColor('#f00')
          // console.log('scrollToRecord, on nextTick,,')
          // console.log(centerContent)
          // console.log(timelineInner)
          // console.log(targetElm)
          if (centerContent && timelineInner && targetElm) {
            centerContent.scrollTop = targetElm.offsetTop + timelineInner.offsetTop + offsetHeight
          }
        })
      }
    },

    highlightRecordBlock (record) {
      // console.log('highlightRecordBlock')
      this.$nextTick(() => {
        const centerContent = this.$refs.mainTimeline
        const targetElm = centerContent.querySelector('#timelineRecord' + record.id)
        // console.log(targetElm)
        if (targetElm) {
          const recordBlock = targetElm.querySelector('.timeline-record-block')
          const dummyBlock = targetElm.querySelector('.timeline-record-dummy-block')
          targetElm.classList.add('highlight')
          if (recordBlock) {
            recordBlock.classList.add('highlight')
          }
          if (dummyBlock) {
            dummyBlock.classList.add('highlight')
          }
        }
      })
    },

    scrollToTimelineTop () {
      this.$nextTick(() => {
        const centerContent = this.$refs.mainTimeline
        centerContent.scrollTop = 0
      })
    },

    scrollToAlbumPhoto (photoID) {
      if (photoID) {
        var offsetHeight = -50
        if (this.signinMember) {
          offsetHeight = -50
        }
        this.$nextTick(() => {
          const centerContent = this.$refs.mainTimeline
          const albumInner = centerContent.querySelector('#albumInner')
          const targetElm = centerContent.querySelector('#photoreport' + photoID)
          if (centerContent && albumInner && targetElm) {
            centerContent.scrollTop = targetElm.offsetTop + albumInner.offsetTop + offsetHeight
          }
        })
      }
    },

    openRecordFile (fileReport) {
      console.log('openRecordFile' + fileReport)
    },

    selectVitalTagForPost () {
      var tag = this.hashTags.filter(tag => tag.tagName === 'med##バイタル')[0]
      if (tag) {
        if (!this.selectedTagsForPost.some(stag => stag.id === tag.id)) {
          this.selectedTagsForPost.push(tag)
        }
      } else {
        this.addNewPostTag('med##バイタル')
      }
    },

    deselectVitalTagForPost () {
      var tag = this.hashTags.filter(tag => tag.tagName === 'med##バイタル')[0]
      if (tag) {
        var tagIndex = this.selectedTagsForPost.indexOf(tag)
        if (tagIndex >= 0) {
          this.selectedTagsForPost.splice(tagIndex, 1)
        }
      } else {
        var tagIndexCreate = this.createTagsForPost.indexOf('med##バイタル')
        if (tagIndexCreate >= 0) {
          this.createTagsForPost.splice(tagIndexCreate, 1)
        }
      }
    },

    selectDrugEntryTagForPost () {
      var tag = this.hashTags.filter(tag => tag.tagName === 'med##お薬情報')[0]
      if (tag) {
        if (!this.selectedTagsForPost.some(stag => stag.id === tag.id)) {
          this.selectedTagsForPost.push(tag)
        }
      } else {
        this.addNewPostTag('med##お薬情報')
      }
    },

    deselectDrugEntryTagForPost () {
      var tag = this.hashTags.filter(tag => tag.tagName === 'med##お薬情報')[0]
      if (tag) {
        var tagIndex = this.selectedTagsForPost.indexOf(tag)
        if (tagIndex >= 0) {
          this.selectedTagsForPost.splice(tagIndex, 1)
        }
      } else {
        var tagIndexCreate = this.createTagsForPost.indexOf('med##お薬情報')
        if (tagIndexCreate >= 0) {
          this.createTagsForPost.splice(tagIndexCreate, 1)
        }
      }
    },

    selectSurrogateTagForPost () {
      var tag = this.hashTags.filter(tag => tag.tagName === '本人##本人曰く')[0]
      if (tag) {
        if (!this.selectedTagsForPost.some(stag => stag.id === tag.id)) {
          this.selectedTagsForPost.push(tag)
        }
      } else {
        // 本人曰くタグが存在しない場合、新規追加タグに本人曰くダグを追加する
        this.addNewPostTag('本人##本人曰く')
      }
    },

    deselectSurrogateTagForPost () {
      var tag = this.hashTags.filter(tag => tag.tagName === '本人##本人曰く')[0]
      if (tag) {
        var tagIndex = this.selectedTagsForPost.indexOf(tag)
        if (tagIndex >= 0) {
          this.selectedTagsForPost.splice(tagIndex, 1)
        }
      } else {
        // 新規追加タグに本人曰くタグがあったら削除
        var tagIndexCreate = this.createTagsForPost.indexOf('本人##本人曰く')
        if (tagIndexCreate >= 0) {
          this.createTagsForPost.splice(tagIndexCreate, 1)
        }
      }
    },

    updateSurrogateTag (surrogate) {
      if (surrogate) {
        this.selectSurrogateTagForPost()
      } else {
        this.deselectSurrogateTagForPost()
      }
    },

    removePushTargetAuthor (author, index) {
      var authorIndex = index
      if (!index >= 0) {
        authorIndex = this.selectTargetForAuthor.indexOf(author)
      }
      if (authorIndex >= 0) {
        this.selectTargetForAuthor.splice(authorIndex, 1)
        this.updatePushTargetGroupState(author)
      }
    },

    // post form and targetDate relation
    targetDateSelectionCancel () {
      this.targetDateSelectionModalClose()
      this.selectTargetDate.change = false
      // this.selectTargetDate.date = null
    },

    targetDateSelectionCommit (selectedDate) {
      this.selectTargetDate.change = true
      this.selectTargetDate.date = new Date(selectedDate.getTime())
      this.targetDateSelectionModalClose()
    },

    // post form heplers
    activatePostForm () {
      this.postFormActive = true
    },

    // reference for post
    infoContentTitleClicked (infoContent, type) {
      this.timelineRecordType = 'normal'
      if (this.referenceInfoContent) {
        this.referenceInfoContent.tags.forEach(tagID => {
          this.removeSelectedTagFromPostByID(tagID)
        })
      }
      this.referenceInfoContent = infoContent
      this.referenceInfoContentType = type
      if (infoContent.tags.length > 0) {
        this.clearNarrowDownAuthors()
        this.clearNarrowDownTags()
        infoContent.hashTags.forEach(tag => {
          this.addNarrowDownTag(tag)
          var tagIndex = this.selectedTagsForPost.indexOf(tag)
          if (tagIndex < 0) {
            this.selectedTagsForPost.push(tag)
          }
        })
      }
      this.$refs.recordPostForm.activateForm()
      this.timelineTargetDate = null
      this.timelineTargetRecord = null
      this.timelineShouldLoad = true
      this.scrollToTimelineTop()
      this.focusCenterColumn()
    },

    removeReferenceForPost () {
      this.referenceInfoContent.tags.forEach(tagID => {
        this.removeSelectedTagFromPostByID(tagID)
      })
      this.referenceInfoContent = null
      this.referenceInfoContentType = ''
      this.clearNarrowDownTags()
      this.timelineShouldLoad = true
    },

    setReplyRecord (record) {
      this.quatedRecordForPost = record
      // タグの引用をセットする
      if (record.tags.length > 0) {
        record.hashTags.forEach(tag => {
          // this.addNarrowDownTag(tag)
          var tagIndex = this.selectedTagsForPost.indexOf(tag)
          if (tagIndex < 0) {
            this.selectedTagsForPost.push(tag)
          }
        })
      }
      this.$refs.recordPostForm.activateForm()
      this.scrollToTimelineTop()
    },

    quoteRecord (record) {
      this.clearPostForm()
      let vitalAvailable = false
      if (this.signinMember && this.signinMember.organization && this.signinMember.organization.medicalExaminationAvailable && record.medicalExamination) {
        vitalAvailable = true
        this.medicalExaminationForPost.blood_pressure_max_value = record.medicalExamination.bloodPressureMax
        this.medicalExaminationForPost.blood_pressure_min_value = record.medicalExamination.bloodPressureMin
        this.medicalExaminationForPost.body_temperature_value = record.medicalExamination.bodyTemperature
        this.medicalExaminationForPost.sp_o2_value = record.medicalExamination.spO2
        this.medicalExaminationForPost.pulse_rate_value = record.medicalExamination.pulseRate
        this.medicalExaminationForPost.irregular_pulse_value = record.medicalExamination.irregularPulse
        this.medicalExaminationForPost.anemia_state_value = record.medicalExamination.anemiaState
        this.medicalExaminationForPost.jaundice_state_value = record.medicalExamination.jaundiceState
        this.medicalExaminationForPost.oral_state_value = record.medicalExamination.oralState
        this.medicalExaminationForPost.respiratory_noize_value = record.medicalExamination.respiratoryNoize
        this.medicalExaminationForPost.respiratory_rate_state_value = record.medicalExamination.respiratoryRateState
        this.medicalExaminationForPost.hart_sound_value = record.medicalExamination.hartSound
        this.medicalExaminationForPost.abdominal_palpation_value = record.medicalExamination.abdominalPalpation
        this.medicalExaminationForPost.abdominal_auscultation_value = record.medicalExamination.abdominalAusculation
        this.medicalExaminationForPost.edema_value = record.medicalExamination.edema
        this.medicalExaminationForPost.bedsore_value = record.medicalExamination.bedsore
        this.medicalExaminationForPost.eating_state_value = record.medicalExamination.eatingState
        this.medicalExaminationForPost.take_medicine_state_value = record.medicalExamination.takeMedicineState
        this.medicalExaminationForPost.urination_state_value = record.medicalExamination.urinationState
        this.medicalExaminationForPost.faces_state_value = record.medicalExamination.facesState
      }
      // TODO: 本人である場合、「本人曰く」のタグをつけないようにする/ Vital権限がない場合、Vitalのタグをつけない
      let isOwner = false
      if (this.loggedInUser && this.loggedInUser.id === record.user_id) {
        isOwner = true
      }
      console.log('isOwner: ' + isOwner + ', vitalAvailable: ' + vitalAvailable)
      this.selectedTagsForPost = []
      for(let tag of this.hashTags) {
        if (record.tags.some(rt => rt === tag.id)) {
          console.log(tag.tagName)
          if (
            !(tag.tagName === '本人##本人曰く' && isOwner) 
            && !(tag.tagName === 'med##バイタル' && !vitalAvailable)
            && (tag.tagName !== 'med##お薬情報')
          ) {
            this.selectedTagsForPost.push(tag)
          }
        }
      }
      this.$refs.recordPostForm.quoteRecord(record)
      this.$refs.recordPostForm.activateForm()
      this.scrollToTimelineTop()
    },

    removeQuatedRecord () {
      if (this.quatedRecordForPost.hashTags.length > 0) {
        this.quatedRecordForPost.hashTags.forEach(tag => {
          this.removeSelectedTagFromPost(tag)
          this.removeNarrowDownTag(tag)
        })
      }
      this.quatedRecordForPost = null
    },

    removeSelectedTagFromPostByID (tagID) {
      // タグのIDからも検索する
      var tagIndex = -1
      this.selectedTagsForPost.forEach((tag, index) => {
        if (tag.id === tagID) {
          tagIndex = index
        }
      })
      if (tagIndex >= 0) {
        this.selectedTagsForPost.splice(tagIndex, 1)
      }
    },

    removeSelectedTagFromPost (targetTag) {
      var tagIndex = this.selectedTagsForPost.indexOf(targetTag)
      console.log('remove selected tag from post : ' + tagIndex)
      if (tagIndex >= 0) {
        this.selectedTagsForPost.splice(tagIndex, 1)
      }
    },

    removeNewPostTagForPost (index) {
      this.createTagsForPost.splice(index, 1)
    },

    removePushTargetAuthorForPost (author, index) {
      var authorIndex = index
      if (!index >= 0) {
        authorIndex = this.pushTargetsForPost.indexOf(author)
      }
      if (authorIndex >= 0) {
        this.pushTargetsForPost.splice(authorIndex, 1)
        this.updatePushTargetGroupState(author)
      }
    },

    clearTargetDateForPost () {
      this.postTargetDate.change = false
      this.postTargetDate.date = null
    },

    openMedicalExaminationEditModal () {
      this.showMedicalExaminationEditModal = true
    },

    medicalExaminationCommit (medEx, isExist, callBack) {
      console.log('medicalExaminationCommit.')
      console.log('medicaiexiaminationis...' + isExist)
      if (isExist) {
        this.selectVitalTagForPost()
      } else {
        this.deselectVitalTagForPost()
      }
      this.medicalExaminationForPost = Object.assign(this.medicalExaminationForPost, medEx)
      this.showMedicalExaminationEditModal = false
      callBack()
    },

    openDrugEntryEditModal () {
      this.showDrugEntryEditModal = true
    },

    drugEntryCommit (drugEntry) {
      // TODO: check object is empty
      if (drugEntry &&
        (
          (drugEntry.comment && drugEntry.comment.length > 0) ||
          (drugEntry.photos && drugEntry.photos.length > 0) ||
          (
            (drugEntry.storedPhotos && drugEntry.storedPhotos.length > 0) &&
            (drugEntry.removePhotos && drugEntry.removePhotos.length > 0) &&
            (drugEntry.storedPhotos.length - drugEntry.removePhotos.length > 0)
          )
        )
      ) {
        this.selectDrugEntryTagForPost()
      } else {
        this.deselectDrugEntryTagForPost()
      }
      this.drugEntryForPost = Object.assign(this.drugEntryForPost, drugEntry)
      this.showDrugEntryEditModal = false
    },

    clearPostForm () {
      this.referenceInfoContent = null
      this.referenceInfoContentType = ''
      this.quatedRecordForPost = null
      this.postTargetDate = { change: false, date: null }
      this.selectedTagsForPost = []
      this.createTagsForPost = []
      this.pushTargetsForPost = []
      this.allFamilyPush = { value: false }
      this.selectedOrganizationsForPush = []
      this.medicalExaminationForPost = {
        blood_pressure_max_value: null,
        blood_pressure_min_value: null,
        body_temperature_value: null,
        sp_o2_value: null,
        pulse_rate_value: null,
        irregular_pulse_value: -1,
        anemia_state_value: -1,
        jaundice_state_value: -1,
        oral_state_value: -1,
        respiratory_noize_value: -1,
        respiratory_rate_state_value: -1,
        hart_sound_value: -1,
        abdominal_palpation_value: -1,
        abdominal_auscultation_value: -1,
        edema_value: -1,
        bedsore_value: -1,
        eating_state_value: -1,
        take_medicine_state_value: -1,
        urination_state_value: -1,
        faces_state_value: -1
      }
      this.drugEntryForPost = {
        comment: '',
        photos: [],
        storedPhotos: [],
        removePhotos: []
      }
      this.postFormActive = false
    },

    // post form general controll
    confirmPostFormClear (callBack) {
      this.showConfirmDialog(
        'フォームのクリア',
        '投稿フォームに入力した内容を全て消去します\nよろしいですか?',
        DIALOG_TYPE.YES_NO,
        () => {
          this.clearPostForm()
          callBack()
        }
      )
    },

    async submitPostForm (formData, successCallback) {
      this.showSendingModal = true
      await TimelineRecord.api().postNewRecord({ formData: formData }).then(async () => {
        await successCallback()
        await this.clearPostForm()
        // checkServerUpdate
        await this.checkTimelineState()
        this.showSendingModal = false
      }).catch(err => {
        console.error(err)
        this.showSendingModal = false
        this.showNotifyDialog('投稿失敗', '投稿の送信に失敗しました')
      })
    },

    // for update controlls
    removeSelectedTagFromEdit (targetTag) {
      var tagIndex = this.selectedTagsForEdit.indexOf(targetTag)
      if (tagIndex >= 0) {
        this.selectedTagsForEdit.splice(tagIndex, 1)
      }
    },

    removeNewPostTagForEdit (index) {
      this.createTagsForEdit.splice(index, 1)
    },

    removePushTargetAuthorFromEdit (author, index) {
      var authorIndex = index
      if (!index >= 0) {
        authorIndex = this.pushTargetsForEdit.indexOf(author)
      }
      if (authorIndex >= 0) {
        this.pushTargetsForEdit.splice(authorIndex, 1)
        this.updatePushTargetGroupState(author)
      }
    },

    clearTargetDateForEdit () {
      this.editTargetDate.change = false
      // this.editTargetDate.date = null
    },

    clearEditForm () {
      this.editTargetRecord = null
      this.editTargetDate = { change: false, date: null }
      this.selectedTagsForEdit = []
      this.createTagsForEdit = []
      this.pushTargetsForEdit = []
      this.allFamilyPushForEdit = { value: false }
      this.selectedOrganizationsForPushToEdit = []
      this.medicalExaminationForPost = {
        blood_pressure_max_value: null,
        blood_pressure_min_value: null,
        body_temperature_value: null,
        sp_o2_value: null,
        pulse_rate_value: null,
        irregular_pulse_value: -1,
        anemia_state_value: -1,
        jaundice_state_value: -1,
        oral_state_value: -1,
        respiratory_noize_value: -1,
        respiratory_rate_state_value: -1,
        hart_sound_value: -1,
        abdominal_palpation_value: -1,
        abdominal_auscultation_value: -1,
        edema_value: -1,
        bedsore_value: -1,
        eating_state_value: -1,
        take_medicine_state_value: -1,
        urination_state_value: -1,
        faces_state_value: -1
      }
      this.drugEntryForPost = {
        comment: '',
        photos: [],
        storedPhotos: [],
        removePhotos: []
      }
    },

    confirmEditFormClear (callBack) {
      this.showConfirmDialog(
        '投稿の編集中止',
        '変更の内容を全て破棄します。\nよろしいですか?',
        DIALOG_TYPE.YES_NO,
        () => {
          this.clearEditForm()
          callBack()
        }
      )
    },

    async submitUpdateForm (formData, removeObjects, successCallback) {
      this.showSendingModal = true
      await TimelineRecord.api().updateRecord({ formData: formData }).then(async () => {
        if (removeObjects.deleteObjects.photos) {
          removeObjects.deleteObjects.photos.forEach(photoID => {
            PhotoReport.delete(photoID)
          })
        }
        if (removeObjects.deleteObjects.files) {
          removeObjects.deleteObjects.files.forEach(fileID => {
            FileReport.delete(fileID)
          })
        }
        if (removeObjects.deleteObjects.drugPhotos) {
          removeObjects.deleteObjects.drugPhotos.forEach(drugPhotoID => {
            DrugEntryPhoto.delete(drugPhotoID)
          })
        }
        await successCallback()
        await this.clearEditForm()
        // checkServerUpdate
        this.timelineTargetRecord = this.editTargetRecord
        await this.checkTimelineState()
        this.showSendingModal = false
      }).catch(err => {
        console.error(err)
        console.error(err.response)
        this.showSendingModal = false
        this.showNotifyDialog('投稿の更新失敗', '投稿の送信に失敗しました')
      })
    },

    // manupurate TimelineRecord
    addThanks (record) {
      // タグの更新のみを行って、リクエストは別に投げる
      if (record.thanks_authors) {
        if (!record.thanks_authors.some(ta => ta === this.loggedInAuthor.id)) {
          record.thanks_authors.push(this.loggedInAuthor.id)
        }
      } else {
        record.thanks_authors = [this.loggedInAuthor.id]
      }
      TimelineRecord.api().addThanks({ timelineOwnerID: this.timelineOwner.id, targetRecordID: record.id }).then(() => {
        // タイムラインのリロードはしない
        // this.checkTimelineState()
      }).catch(err => {
        console.error(err.response)
      })
    },

    removeThanks (record) {
      // タグの更新のみを行って、リクエストは別に投げる
      if (record.thanks_authors) {
        var thanksIndex = record.thanks_authors.indexOf(this.loggedInAuthor.id)
        if (thanksIndex >= 0) {
          record.thanks_authors.splice(thanksIndex, 1)
        }
      } else {
        record.thanks_authors = []
      }
      TimelineRecord.api().removeThanks({ timelineOwnerID: this.timelineOwner.id, targetRecordID: record.id }).then(() => {
        // タイムラインのリロードはしない
        // this.checkTimelineState()
      }).catch(err => {
        console.error(err.response)
      })
    },

    addNudge (record) {
      // タグの更新のみを行って、リクエストは別に投げる
      record.nudged = true
      NudgedRecord.api().create({ timelineOwnerID: this.timelineOwner.id, targetRecordID: record.id }).then(() => {
        // this.checkTimelineState()
        // ナッジレコードのみを更新する
        this.loadNudgeRecords()
      }).catch(err => {
        console.error(err.response)
      })
    },

    removeNudge (record) {
      // タグの更新のみを行って、リクエストは別に投げる
      record.nudged = false
      NudgedRecord.api().destroy({ timelineOwnerID: this.timelineOwner.id, targetRecordID: record.id }).then(() => {
        // this.checkTimelineState()
        // ナッジレコードのみを更新する
        this.loadNudgeRecords()
      }).catch(err => {
        console.error(err.response)
      })
    },

    destroyRecord (record) {
      if (record.user_id === this.loggedInAuthor.rawID || record.author_id === this.loggedInAuthor.id) {
        this.showConfirmDialog(
          '投稿の削除',
          '投稿を削除しようとしています。\n本当に削除してよろしいですか?',
          DIALOG_TYPE.EXECUTE,
          () => {
            // 削除実行
            TimelineRecord.api().destroyRecord({ timelineOwnerID: this.timelineOwner.id, targetRecordID: record.id }).then(() => {
              this.checkTimelineState()
            })
          }
        )
      }
    },

    editRecord (record) {
      if (this.postFormActive) {
        // ダイアログで確認する
        this.showConfirmDialog(
          '投稿の作成中',
          '現在、新規投稿の作成中です。新規投稿の作成中は、別の投稿の編集は行えません。\n新規投稿の作成を中止して、投稿の編集を行えますが、その場合、作成中の内容は破棄されます。\n作成中の新規投稿を削除して、投稿の編集を行いますか?',
          DIALOG_TYPE.EXECUTE,
          () => {
            // 削除実行
            this.clearPostForm()
            this.clearEditForm()
            this.editTargetRecord = record
            this.editTargetDate.date = record.targetAt
            this.selectedTagsForEdit = record.hashTags
            this.prepareEditMedicalExamination(record.medicalExamination)
            this.prepareDrugEntry(record.drugEntry)
          }
        )
      } else {
        this.clearEditForm()
        this.editTargetRecord = record
        this.editTargetDate.date = record.targetAt
        this.selectedTagsForEdit = record.hashTags
        this.prepareEditMedicalExamination(record.medicalExamination)
        this.prepareDrugEntry(record.drugEntry)
      }
    },

    prepareEditMedicalExamination (medicalExamination) {
      if (medicalExamination) {
        this.medicalExaminationForPost.blood_pressure_max_value = medicalExamination.bloodPressureMax
        this.medicalExaminationForPost.blood_pressure_min_value = medicalExamination.bloodPressureMin
        this.medicalExaminationForPost.body_temperature_value = medicalExamination.bodyTemperature
        this.medicalExaminationForPost.sp_o2_value = medicalExamination.spO2
        this.medicalExaminationForPost.pulse_rate_value = medicalExamination.pulseRate
        this.medicalExaminationForPost.irregular_pulse_value = medicalExamination.irregularPulse
        this.medicalExaminationForPost.anemia_state_value = medicalExamination.anemiaState
        this.medicalExaminationForPost.jaundice_state_value = medicalExamination.jaundiceState
        this.medicalExaminationForPost.oral_state_value = medicalExamination.oralState
        this.medicalExaminationForPost.respiratory_noize_value = medicalExamination.respiratoryNoize
        this.medicalExaminationForPost.respiratory_rate_state_value = medicalExamination.respiratoryRateState
        this.medicalExaminationForPost.hart_sound_value = medicalExamination.hartSound
        this.medicalExaminationForPost.abdominal_palpation_value = medicalExamination.abdominalPalpation
        this.medicalExaminationForPost.abdominal_auscultation_value = medicalExamination.abdominalAusculation
        this.medicalExaminationForPost.edema_value = medicalExamination.edema
        this.medicalExaminationForPost.bedsore_value = medicalExamination.bedsore
        this.medicalExaminationForPost.eating_state_value = medicalExamination.eatingState
        this.medicalExaminationForPost.take_medicine_state_value = medicalExamination.takeMedicineState
        this.medicalExaminationForPost.urination_state_value = medicalExamination.urinationState
        this.medicalExaminationForPost.faces_state_value = medicalExamination.facesState
      }
    },

    prepareDrugEntry (drugEntry) {
      if (drugEntry) {
        if (drugEntry.comment) {
          this.drugEntryForPost.comment = drugEntry.comment
        } else {
          this.drugEntryForPost.comment = ''
        }
        if (drugEntry.drugEntryPhotos) {
          this.drugEntryForPost.storedPhotos = drugEntry.drugEntryPhotos
        }
        this.drugEntryForPost.photos = []
        this.drugEntryForPost.removePhotos = []
      }
    },

    moveToTargetOwnerTimeline (author) {
      const targetOwnerID = author.id.replace('usr', '')
      this.$router.push('/maintimeline/' + targetOwnerID)
    },

    showTaskConfiguration () {
      this.$emit('showTaskConfigurationEvent')
    }
  }
}
</script>

<style lang="scss">
@import '../assets/css/mediaQuery.scss';
@import '../assets/css/_basicInputStyle.scss';

.horus-main-timeline-view {
  height: calc(100% - 50px);
}

.main-timeline-content {
  display: flex;
  background-color: #f5f5f5;
  height: calc(100% - 41px - 10px);
  padding: 10px 15px 0;
  &.sub-header-visible {
    height: calc(100% - 41px - 10px - 61px);
  }
  @include mediaQuery('phone') {
    padding: 10px 0 0;
  }
}

.left-content {
  position: relative;
  padding: 0;
  width: 350px;
  height: 100%;
  overflow: hidden auto;
  @include mediaQuery('tablet') {
    width: 250px;
  }
  @include mediaQuery('phone') {
    width: 100%;
    padding: 0 10px;
  }
  h3 {
    margin: 20px 0 5px 0;
    color: #666;
    font-size: 1em;
    text-align: left;
    a {
      color: #666;
      text-decoration: none;
      cursor: pointer;
    }
    img {
      margin-right: 5px;
      width: 10px;
    }
    .info-content-toggle {
      width: 15px;
    }
  }
  .left-column-contents {
    width: 350px;
    @include mediaQuery('tablet') {
      width: 250px;
    }
    @include mediaQuery('phone') {
      width: calc(100% - 10px);
    }
  }
}

.center-content {
  position: relative;
  padding: 10px;
  width: calc(100% - 350px);
  overflow: auto;
  transition-property: all;
  transition-duration: 300ms;
  @include mediaQuery('tablet') {
    width: calc(100% - 250px);
  }
  &.wide {
    width: 100%;
  }
  .month-splitter {
    margin: 10px;
    text-align: center;
    padding-bottom: 3px;
    border-bottom: 1px solid #aaa;
  }
  .load-button-block {
    width: 170px;
    margin: 10px auto 5px;
    .load-btn {
      padding: 3px 10px;
      border: none;
      border-radius: 3px;
      background-color: #666;
      color: #fff;
      font-size: 0.8em;
      font-weight: bold;
      cursor: pointer;
    }
  }
  h1.centering-title {
    margin: 0 0 10px;
    text-align: center;
    font-size: 1em;
    font-weight: bold;
    color: #999;
    img {
      margin-right: 5px;
      width: 10px;
    }
  }
  .normal-record-timeline {
    position: relative;
  }
}

.right-content {
  position: relative;
  padding: 0;
  padding-left: 10px;
  width: 350px;
  height: 100%;
  overflow: hidden auto;
  @include mediaQuery('tablet') {
    width: 250px;
  }
  @include mediaQuery('phone') {
    width: 100%;
  }
}

.general-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1500;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.01;
  cursor: pointer;
}

.now-loading {
  padding: 5px;
  // border: 1px solid #ddd;
  // background-color: #eee;
  color: #999;
  text-align: center;
  .progress {
    margin-right: 10px;
    height: 30px;
    width: 30px;
  }
}
// transitions
.left-column-enter-active, .left-column-leave-active {
  transition-property: all;
  transition-duration: 300ms;
  width: 350px;
  @include mediaQuery('tablet') {
    width: 250px;
  }
  @include mediaQuery('phone') {
    width: 100%;
    padding: 0 10px;
  }
}
.left-column-enter, .left-column-leave-to {
  width: 0;
}

.right-column-enter-active, .right-column-leave-active {
  transition-property: all;
  transition-duration: 300ms;
  width: 350px;
  @include mediaQuery('tablet') {
    width: 250px;
  }
  @include mediaQuery('phone') {
    width: 100%;
  }
}
.right-column-enter, .right-column-leave-to {
  width: 0;
}

.modal-enter-active, .modal-leave {
  transition-property: opacity;
  transition-duration: 3s;
  opacity: 1;
}
.modal-enter, .modal-leave-to {
  opacity: 0;
}
</style>
