<template>
  <div class="horus-personal-information-protection-policy container">
    <div class="navbar">
      <div class="main-bar">
        <a href="/landing"><img src="/static/images/narrative_icon_w.png" class="navi-logo-icon"></a>
      </div>
    </div>
    <div class="contents">
      <div class="main-contents jp" v-if="displayLang === 'jp'">
        <h1>個人情報保護方針<a href="/personal_information_protection_policy/en" class="switch-lang">English</a></h1>
        <div class="entry-body">
          <p>当社は、当社が取り扱う全ての個人情報の保護について、社会的使命を十分に認識し、本人の権利の保護、個人情報に関する法規制等を遵守します。また、以下に示す方針を具現化するための個人情報保護マネジメントシステムを構築し、最新のＩＴ技術の動向、社会的要請の変化、経営環境の変動等を常に認識しながら、その継続的改善に、全社を挙げて取り組むことをここに宣言します。</p>
          <ol>
            <li>個人情報は、生活に関わるアプリケーション開発・運用・サービスの提供業務における当社の正当な事業遂行上並びに従業員の雇用、人事管理上必要な範囲に限定して、取得・利用及び提供をし、特定された利用目的の達成に必要な範囲を超えた個人情報の取扱い（目的外利用）を行いません。また、目的外利用を行わないための措置を講じます。</li>
            <li>個人情報保護に関する法令、国が定める指針及びその他の規範を遵守致します。</li>
            <li>個人情報の漏えい、滅失、き損などのリスクに対しては、合理的な安全対策を講じて防止すべく事業の実情に合致した経営資源を注入し個人情報セキュリティ体制を継続的に向上させます。また、万一の際には速やかに是正措置を講じます。</li>
            <li>個人情報取扱いに関する苦情及び相談に対しては、迅速かつ誠実に、適切な対応をさせていただきます。</li>
            <li>個人情報保護マネジメントシステムは、当社を取り巻く環境の変化を踏まえ、適時・適切に見直してその改善を継続的に推進します。</li>
          </ol>
          <p>本方針は、全ての従業者に配付して周知させるとともに、当社のホームページ、パンフレットなどに掲載することにより、いつでもどなたにも入手可能な措置を取るものとします。</p>
          <p class="right-align">
            制定&nbsp;2015年11月17日<br>
            改定&nbsp;2015年11月17日<br>
            クロスケアフィールド株式会社<br>
            代表取締役 社長 岡﨑 光洋
          </p>
          <h2>【お問合せ窓口】</h2>
          <p>個人情報保護方針に関するお問合せにつきましては、下記窓口で受付けております。</p>
          <p>
            クロスケアフィールド株式会社 &nbsp;個人情報問合せ窓口<br>
            〒110-0005&nbsp;東京都台東区上野3-7-3 SDビル2階<br>
            メールアドレス:<a href="https://xcf.co.jp/wp/wp-content/uploads/2012/01/786c5b7d879c44fdb8388ce40c82bb8c.png" rel="lightbox[31]"><img loading="lazy" class="alignnone size-full wp-image-665" src="https://xcf.co.jp/wp/wp-content/uploads/2012/01/786c5b7d879c44fdb8388ce40c82bb8c.png" alt="Pマークメールアドレス3" width="134" height="26"></a><br>
            TEL:03-3831-7755 （受付時間&nbsp;10:00～17:00※）<br>
            ※ 土・日曜日、祝日、年末年始、ゴールデンウィーク期間は翌営業日以降の対応とさせていただきます。
          </p>
          <h2>【個人情報に関する公表文】</h2>
          <h3>■個人情報の取扱いについて</h3>
          <p>
            １．当社が取り扱う個人情報の利用目的<br>
            （1）ご本人から直接書面によって取得する個人情報（ホームページや電子メール等によるものを含む）の利用目的<br>
            ・取得に先立ち、ご本人に対し書面により明示します。<br>
            （2）前項以外の方法によって取得する個人情報の利用目的
          </p>
          <table>
            <tbody>
              <tr>
                <th class="index">分類</th>
                <th class="contents">利用目的</th>
              </tr>
              <tr>
                <td class="index">個人のお客様情報(電話等で問合せを頂いた場合)</td>
                <td class="contents">ご利用履歴管理のため<br>お問合せ対応のため</td>
              </tr>
              <tr>
                <td class="index">医療・介護・福祉関連施設及び事業所のお客様情報(受託等で申し込みをいただいた場合)</td>
                <td class="contents">ご利用開始手続きのため<br>サービス提供及び運用保守のため</td>
              </tr>
            </tbody>
          </table>
          <h3>■保有個人データに関する事項の周知</h3>
          <p>当社で保有している保有個人データに関して、ご本人様又はその代理人様からの利用目的の通知、開示、内容の訂正、追加又は削除、利用の停止、消去及び第三者への提供の停止の請求（以下、「開示等の請求」といいます）につきましては、以下の要領にて対応させていただきます。</p>
          <ol style="list-style-type: lower-alpha;">
            <li>
              事業者の名称<br>
              クロスケアフィールド株式会社<br>
              〒110-0005 東京都台東区上野3-7-3 SDビル2階<br>
              代表取締役 社長 岡﨑 光洋
            </li>
            <li>個人情報の保護管理者<br>管理者職名:代表取締役<br>連絡先:03-3831-7755</li>
            <li>全ての保有個人データの利用目的</li>
          </ol>
          <table class="handling">
            <tbody>
              <tr>
                <th class="index">分類</th>
                <th class="contents">利用目的</th>
              </tr>
              <tr>
                <td class="index">会員様情報</td>
                <td class="contents">
                  会員様サポートのため<br>
                  ご利用履歴管理のため<br>
                  当社サービスのご案内のため<br>
                  お問合せ対応のため
                </td>
              </tr>
              <tr>
                <td class="index">委託先様情報</td>
                <td class="contents">
                  業務管理のため<br>
                  委託料の支払いのため<br>
                  セキュリティ管理のため
                </td>
              </tr>
              <tr>
                <td class="index">当社従業員情報</td>
                <td class="contents">社員の人事労務管理、業務管理、健康管理、セキュリティ管理のため</td>
              </tr>
              <tr>
                <td class="index">当社への採用応募者情報</td>
                <td class="contents">採用応募者への連絡と当社の採用業務管理のため</td>
              </tr>
              <tr>
                <td class="index">特定個人情報</td>
                <td class="contents">番号法に定められた利用目的のため</td>
              </tr>
            </tbody>
          </table>
          <ol style="list-style-type: lower-alpha;" start="4">
            <li>
              保有個人データの取扱いに関する苦情の申し出先<br>
              クロスケアフィールド株式会社 &nbsp;個人情報問合せ窓口<br>
              〒110-0005&nbsp;東京都台東区上野3-7-3 SDビル2階<br>
              TEL:03-3831-7755 （受付時間&nbsp;10:00～17:00※）<br>
              ※ 土・日曜日、祝日、年末年始、ゴールデンウィーク期間は翌営業日以降の対応とさせていただきます。
            </li>
            <li>
              認定個人情報保護団体<br>
              現在、当社が加盟する認定個人情報保護団体はありません。
            </li>
            <li>
              保有個人データの開示等の求めに応じる手続き
              <ol style="list-style-type: lower-roman;">
                <li>
                  開示等の求めの申し出先<br>
                  開示等のお求めは、上記個人情報問合せ係にお申し出ください。<br>
                  ※電磁的手続きによる開示等をご希望の方は、その旨お申し出ください。原則としてご希望に沿って対応させていただきます。
                </li>
                <li>開示等の求めに関するお手続き
                  <ol>
                  <li>お申し出受付け後、当社からご利用いただく所定の請求書様式「保有個人データ開示等請求書」を郵送いたします。</li>
                  <li>ご記入いただいた請求書、代理人によるお求めの場合は代理人であることを確認する書類、手数料分の郵便為替（利用目的の通知並びに開示の請求の場合のみ）を上記個人情報問合せ係までご郵送ください。</li>
                  <li>上記請求書を受領後、ご本人確認のため、当社に登録していただいている個人情報のうちご本人確認可能な項目の内容をお問合せさせていただきます。</li>
                  </ol>
                </li>
                <li>
                  代理人によるお求めの場合、代理人であることを確認する資料<br>
                  開示等をお求めになる方が代理人様である場合は、代理人である事を証明する資料及び代理人様ご自身を証明する資料を同封してください。各資料に含まれる本籍地情報は都道府県までとし、それ以降の情報は黒塗り等の処理をしてください。また各資料は個人番号を含まないものをお送りいただくか、全桁を墨塗り等の処理をしてください。
                  <ol>
                    <li>
                      代理人である事を証明する資料<br>
                      ＜開示等の求めをすることにつき本人が委任した代理人様の場合＞<br>
                      ・本人の委任状（原本）＜代理人様が未成年者の法定代理人の場合＞ いずれかの写し<br>
                      ・戸籍謄本<br>
                      ・住民票（続柄の記載されたもの）<br>
                      ・その他法定代理権の確認ができる公的書類＜代理人様が成年被後見人の法定代理人の場合＞ いずれかの写し<br>
                      ・後見登記等に関する登記事項証明書<br>
                      ・その他法定代理権の確認ができる公的書類
                    </li>
                    <li>代理人様ご自身を証明する資料
                      <ul style="list-style-type: circle;">
                        <li>運転免許証</li>
                        <li>パスポート</li>
                        <li>住民票</li>
                        <li>住民基本台帳カード</li>
                      </ul>
                    </li>
                  </ol>
                </li>
                <li>
                  利用目的の通知または開示のお求めについての手数料<br>
                  1回のお求めにつき1000円<br>
                  （書面でのご請求の場合は、お送りいただく請求書等に郵便為替を同封していただきます。その他の方法でご請求いただく場合は、ご請求時にご相談させていただきます。）
                </li>
              </ol>
            </li>
          </ol>
          <ol style="list-style-type: lower-alpha;" start="7">
            <li>
              保有個人データの安全管理のために講じた措置
              <ol style="list-style-type: lower-roman;">
                <li>
                  基本方針の策定
                  保有個人データの適正な取扱いの確保のため、「関係法令・ガイドライン等の遵守」、「質問及び苦情処理の窓口」等について「個人情報保護方針」を策定しています。
                </li>
                <li>
                  保有個人データの取扱いに係る規律の整備
                  保有個人データは取得、利用、保存、提供、削除・廃棄等の段階ごとに、取扱方法、責任者・担当者及びその任務等について個人情報保護規程を策定しています。
                </li>
                <li>
                  組織的安全管理措置
                  <ol>
                    <li>保有個人データの取扱いに関する責任者を設置するとともに、保有個人データを取り扱う従業者及び当該従業者が取り扱う保有個人データの範囲を明確化し、法や取扱規程に違反している事実又は兆候を把握した場合の責任者への報告連絡体制を整備しています。</li>
                    <li>保有個人データの取扱状況について、定期的に自己点検を実施するとともに、他部署や外部の者による監査を実施しています。</li>
                  </ol>
                </li>
                <li>
                  人的安全管理措置
                  <ol>
                    <li>保有個人データの取扱いに関する留意事項について、従業者に定期的な研修を実施しています。</li>
                    <li>保有個人データを含む秘密保持に関する誓約書の提出を全従業者から受けています。</li>
                  </ol>
                </li>
                <li>
                  物理的安全管理措置
                  <ol>
                    <li>保有個人データを取り扱う区域において、従業者の入退室管理及び持ち込む機器等の制限を行うとともに、権限を有しない者による保有個人データの閲覧を防止する措置を講じています。</li>
                    <li>保有個人データを取り扱う機器、電子媒体及び書類等の盗難又は紛失等を防止するための措置を講じるとともに、事業所内の移動を含め、当該機器、電子媒体等を持ち運ぶ場合、容易に保有個人データが判明しないよう措置を講じています。</li>
                  </ol>
                </li>
                <li>
                  技術的安全管理措置
                  <ol>
                    <li>アクセス制御を実施して、担当者及び取り扱う個人情報データベース等の範囲を限定しています。</li>
                    <li>保有個人データを取り扱う情報システムを外部からの不正アクセス又は不正ソフトウェアから保護する仕組みを導入しています。</li>
                  </ol>
                </li>
              </ol>
            </li>
          </ol>
          <p>以上</p>
        </div>
      </div>
      <div class="main-contents en" v-if="displayLang === 'en'">
        <h1>Personal Information Protection Policy<a href="/personal_information_protection_policy/jp" class="switch-lang">日本語</a></h1>
        <div class="entry-body">
          <p>We are fully aware of our social mission to protect all personal information that we handle, and we will comply with laws and regulations regarding the protection of the rights of the individual and personal information.</p>
          <p>In addition, we hereby declare that we will establish a personal information protection management system to realize the following policies, and that we will make corporation-wide efforts to continuously improve the system while always being aware of the latest trends in information technology, changes in social demands, and changes in the business environment.</p>
          <ol>
            <li>Personal information shall be obtained, used, and provided only to the extent necessary for the execution of our legitimate business in the development, operation, and provision of services for applications related to daily life, as well as for the employment and personnel management of our employees, and personal information shall not be handled beyond the scope necessary to achieve the specified purpose of use (use for other purposes). We will not use personal information for purposes other than those specified. We will not use personal information beyond the scope necessary to achieve the specified purpose of use (use for other purposes). We will also take measures to prevent use for other purposes.</li>
            <li>We will respond promptly, sincerely, and appropriately to complaints and consultations regarding the handling of personal information.</li>
            <li>The personal information protection management system will be reviewed in a timely and appropriate manner in light of changes in the environment surrounding the corporation, and improvements will be promoted on an ongoing basis.</li>
            <li>This policy shall be distributed to all employees to make them aware of it, and shall be made available to anyone at any time by posting it on the corporation’s website and pamphlets.</li>
          </ol>
          <p class="right-align">
            Enacted November 17, 2015<br/>
            Revised November 17, 2015<br/>
            xCareField Co., Ltd.<br/>
            Mitsuhiro Okazaki, President and Representative Director
          </p>
          <h2>FOR MORE INFORMATION&nbsp; ───</h2>
          <p>For inquiries regarding our privacy policy, please contact the following</p>
          <p>xCareField Co., Ltd. Personal Information Inquiry Desk</p>
          <p>SD Building 2F, 3-7-3 Ueno, Taito-ku, Tokyo 110-0005, Japan</p>
          <p>E-mail address: P mark e-mail address 3</p>
          <p>TEL: 03-3831-7755 (Office hours: 10:00 – 17:00*)</p>
          <p>Please note that inquiries received on Saturdays, Sundays, national holidays, year-end and New Year holidays, and during the Golden Week holidays will be handled on the next business day or later.</p>
          <h2>PUBLIC STATEMENT REGARDING PERSONAL INFORMATION</h2>
          <h3>Handling of Personal Information</h3>
          <ol>
            <li>Purpose of use of personal information handled by the Company</li>
            <li>
              Purposes of use of personal information obtained directly from the person in writing (including through websites, e-mail, etc.)
              <ul>
                <li>Prior to acquisition, we will clearly notify the person in writing.</li>
              </ul>
            </li>
            <li>Purpose of use of personal information acquired by methods other than those described in the preceding paragraph</li>
          </ol>
          <table class="handling">
            <tbody>
              <tr>
                <th class="index">Classification</th>
                <th class="contents">Purpose of use</th>
              </tr>
              <tr>
                <td class="index">Personal customer information(When we receive an inquiry by telephone, etc.)</td>
                <td class="contents">To manage usage historyTo respond to inquiries</td></tr><tr><td>Customer information for medical, nursing care, and welfare related facilities and offices(When an application is received by commissioning, etc.)</td><td>For start-up proceduresFor service provision, operation and maintenance</td>
              </tr>
            </tbody>
          </table>
          <h3>Dissemination of matters concerning personal information subject to disclosure</h3>
          <p>With regard to the personal information subject to disclosure held by our company, we will respond to requests from the person in question or their representative for notification of the purpose of use, disclosure, correction, addition or deletion of content, suspension of use, deletion, and suspension of provision to third parties (hereinafter referred to as “requests for disclosure, etc.”) in the following manner</p>
          <ol style="list-style-type: lower-alpha;">
            <li>
              <strong>Name of the business</strong><br/>
              xCareField Co., Ltd.
            </li>
            <li>
              <strong>Personal Information Protection Manager</strong><br/>
              Manager’s position: Personal Information Protection Manager<br/>
              Contact: 03-3831-7755
            </li>
            <li>
              <strong>Purpose of use of all personal information subject to disclosure</strong>
              <table>
                <tbody>
                  <tr>
                    <th>Classification</th>
                    <th>Purpose of use</th>
                  </tr>
                  <tr>
                    <td>Member Information</td>
                    <td>
                      To support our members<br>
                      To manage usage history<br>
                      To provide information about our services<br>
                      To respond to inquiries
                    </td>
                  </tr>
                  <tr>
                    <td>Contractor Information</td>
                    <td>For business managementFor payment of consignment feesFor security management</td>
                  </tr>
                  <tr>
                    <td>Information on our employees</td>
                    <td>For personnel and labor management, business management, health management, and security management of employees</td>
                  </tr>
                  <tr>
                    <td>Information on applicants for employment with the Company</td>
                    <td>To contact applicants for employment and to manage our recruitment operations</td>
                  </tr>
                  <tr>
                    <td>Specific personal information</td>
                    <td>For the purposes of use stipulated in the Number Law</td>
                  </tr>
                </tbody>
              </table>
            </li>
            <li>
              <strong>Where to make complaints regarding the handling of personal information subject to disclosure</strong><br>
              xCareField Co., Ltd. Personal information inquiry desk.<br>
              2nd floor of SD Building, 3-7-3 Ueno, Taito-ku, Tokyo 110-0005<br>
              TEL: 03-3831-7755 (Reception hours 10: 00-17: 00※)<br>
              * Please note that inquiries received on Saturdays, Sundays, national holidays, year-end and New Year holidays, and during the Golden Week holidays will be handled on the next business day or later.
            </li>
            <li>
              <strong>Authorized personal information protection organization</strong><br>
              Currently, there are no authorized personal information protection organizations to which we belong.
            </li>
            <li>
              <strong>Procedures for responding to requests for disclosure of personal information subject to disclosure.</strong><br>
              <ol style="list-style-type: lower-roman;">
                <li>
                  Request reception desk for disclosure, etc.<br>
                  For requests for disclosure, etc., please contact the above personal information inquiry section.
                </li>
                <li>
                  Procedures for requesting disclosure, etc.
                  <ol>
                    <li>After accepting your request, we will mail you the prescribed invoice form "Invoice for Disclosure of Personal Information Subject to Disclosure" to be used by us.</li>
                    <li>Please contact the above personal information inquiry section with the invoice you filled out, documents confirming that you are an agent if requested by an agent, and postal exchange for the fee (only for notification of purpose of use and request for disclosure). Please mail it.</li>
                    <li>After receiving the above invoice, we will inquire about the contents of the personal information registered with us that can be verified for identity verification.</li>
                    <li>As a general rule, we will reply to you in writing (by mail).</li>
                  </ol>
                </li>
                <li>
                  Documents to confirm that you are an agent when requested by an agent<br>
                  If the person requesting disclosure, etc. is an agent, please enclose the material certifying that you are the agent and the material certifying yourself. The registered domicile information included in each material should be up to the prefecture, and the information after that should be blacked out. In addition, please send each material without personal number, or process all digits with black ink.
                  <ol>
                    <li>
                      Material proving that you are an agent<br>
                      &gt;In the case of an agent delegated by the person himself / herself to request disclosure, etc.&lt;
                      <ul>
                        <li>Power of attorney (original) of the person &gt;If the agent is a legal agent of a minor&lt; A copy of either</li>
                        <li>Family register copy</li>
                        <li>Resident's card (with relationship)</li>
                        <li>Other official documents that can confirm the legal representation right &gt;If the agent is the legal representative of an adult ward&lt; A copy of one of them</li>
                        <li>Registration certificate regarding guardianship registration, etc.</li>
                        <li>Other official documents that can confirm the legal agency right.</li>
                      </ul>
                    </li>
                    <li>
                      Documents that prove the agent himself.
                      <ul>
                        <li>Driver's license</li>
                        <li>passport</li>
                        <li>Resident's card</li>
                        <li>Basic Resident Register Card</li>
                      </ul>
                    </li>
                  </ol>
                </li>
                <li>
                  Fees for requesting notification or disclosure of purpose of use.<br>
                  1000 yen per request (Please enclose the postal exchange with the invoice you send.)
                </li>
              </ol>
            </li>
          </ol>
          <p class="right-align">below margin</p>
        </div>
      </div>
    </div><!-- [ /#post-797 ] -->
    <horus-footer />
  </div>
</template>

<script>
import HorusFooter from '@/components/common/HorusFooter.vue'

export default {
  name: 'HorusPersonalInformationProtectionPolicy',

  components: {
    HorusFooter
  },

  props: {
    lang: String
  },

  data () {
    return {
      displayLang: 'jp'
    }
  },

  watch: {
    '$attrs': { handler: 'initLang', immediate: true }
  },

  methods: {
    initLang () {
      if (this.lang[0] === 'en') {
        this.displayLang = 'en'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mediaQuery.scss';

.horus-personal-information-protection-policy {
  background-color: #eee;
  .contents {
    min-height: calc(100% - 50px - 40px - 132px);
    padding: 20px 60px;
    background-color: #fff;
    @include mediaQuery('phone') {
      padding: 10px 20px;
    }
    h1 {
      margin: 0;
      padding: 0;
      font-size: 1.4em;
      font-weight: bold;
      .switch-lang {
        padding-left: 20px;
        font-size: 12px;
        font-weight: normal;
      }
    }
    h2 {
      margin: 0;
      margin-top: 20px;
      font-size: 1.1em;
      font-weight: bold;
    }
    h3 {
      margin: 0;
      margin-top: 10px;
      font-size: 1em;
      font-weight: bold;
    }
    p {
      margin: 5px 0;
    }
    p.right-align {
      text-align: right;
    }
    ol {
      margin: 10px 0;
      padding-inline-start: 20px;
      @include mediaQuery('phone') {
        padding-inline-start: 10px;
      }
      li {
        margin: 8px;
      }
    }
    table {
      margin: 10px 0;
      width: 100%;
      border: 1px solid #999;
      border-collapse: collapse;
      &.handling {
        margin-left: 40px;
        width: calc(100% - 40px);
        @include mediaQuery('phone') {
          margin-left: 20px;
          width: calc(100% - 20px);
        }
      }
      th {
        border: 1px solid #999;
        padding: 10px;
        background-color: #ddd;
        text-align: left;
      }
      td {
        border: 1px solid #999;
        padding: 10px;
      }
      .index {
        width: 30%;
      }
    }
    .main-contents.en {
      word-break: normal;
    }
  }
}
</style>
