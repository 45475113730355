import axios from './AxiosInstance'

export default {

  fetch: ({ targetUserID }) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'omron_connect_account',
        withCredentials: true,
        params: { target_user_id: targetUserID }
      }).then(res => {
        resolve({ omronConnectAccount: res.data.omron_connect_account })
      }).catch(error => {
        reject(error)
      })
    })
  },

  create: ({ targetUserID, omronConnectID }) => {
    var formData = new FormData()
    formData.append('target_user_id', targetUserID)
    formData.append('omron_connect_id', omronConnectID)
    return axios.csrfHeader('POST').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'post',
            url: 'omron_connect_account',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          console.log(error)
          reject(error)
        })
      })
    })
  },

  destroy: ({ targetUserID }) => {
    return axios.csrfHeader('DELETE').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'delete',
            url: 'omron_connect_account',
            withCredentials: true,
            params: { target_user_id: targetUserID }
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  updateVitals: ({ targetUserID }) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'omron_connect_account/fetch_vitals',
        withCredentials: true,
        params: { target_user_id: targetUserID }
      }).then(res => {
        resolve(res.data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  forceReloadVitals: ({ targetUserID }) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'omron_connect_account/force_reload_vitals',
        withCredentials: true,
        params: { target_user_id: targetUserID }
      }).then(res => {
        resolve(res.data)
      }).catch(error => {
        reject(error)
      })
    })
  }

}
