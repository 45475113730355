<template>
  <div class="horus-cookie-policy">
    <div class="cookie-policy-content jp" v-if="displayLang === 'jp'">
      <h1>Cookieポリシー</h1>
        <h2>Cookie情報の取得について</h2>
        <p>本サービスのウェブサイト及び専用アプリケーションでは、お客様がより便利にウェブサイトを閲覧していただくため、また継続的にウェブサイトを改善する目的でCookieを取得しています。</p>
        <h3>《ウェブサイト一覧》</h3>
        <p>・https://narrativebook.jp/</p>
        <p>
          お客様の端末に保存されたCookieは、一定の有効期限が切れるまで、またはお客様ご自身で削除するまで、端末上に保存されます。<br/>
          Cookieはブラウザの設定から無効化することができますが、Cookieを無効にすると、当社のサービスの利用にあたりログイン認証ができなくなる等のおそれがあります。
        </p>
        <h3>ウェブサイトにおける外部送信について</h3>
        <p>
          当社のウェブサイト及び専用アプリケーションでは、以下の事業者が提供するツール等を利用しています。<br/>
          各ツールの利用目的及び各事業者におけるCookie等を通じて取得した情報の取り扱い方針（プライバシーに関する記載）は以下のとおりです。
        </p>
        <h3>《ウェブツール：reCAPTCHA》</h3>
        <h4>・提供企業：Google LLC</h4>
        <h4>・利用目的</h4>
        <p>ウェブサイトやアプリのセキュリティを確保し、また、不正利用等の検知を行い適切な対応を行うための機能を提供しています。</p>
        <h4>・送信情報例</h4>
        <p>
          IPアドレス<br/>
          閲覧したサイトのURLやアプリの画面名<br/>
          閲覧日時（滞在時間などの算出に用いられます。）<br/>
          ウェブブラウザやデバイスの種類、バージョン<br/>
        </p>
        <h4>・プライバシーポリシー：<a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a></h4>
    </div>
    <div class="cookie-policy-content jp" v-if="displayLang === 'en'">
      <h1>Cookie policy</h1>
      <h2>About obtaining cookie information</h2>
      <p>The website and dedicated applications of this service acquire cookies in order to make it more convenient for customers to browse the website and to continuously improve the website.</p>
      <h3>《Website list》</h3>
      <p>・https://narrativebook.jp/</p>
      <p>
        Cookies stored on your device will remain on the device until a certain expiration date or until you delete them yourself.<br/>
        Cookies can be disabled in your browser settings, but disabling cookies may result in you being unable to log in and authenticate when using our services.
      </p>
      <h3>About external transmission on the website</h3>
      <p>
        Our website and dedicated application use tools provided by the following operators.<br/>
        The purpose of use of each tool and the handling policy (privacy statement) of information acquired through cookies by each operator are as follows.
      </p>
      <h3>《Web tool: reCAPTCHA》</h3>
      <h4>・Provided by: Google LLC</h4>
      <h4>・Purpose of use</h4>
      <p>We provide functions to ensure the security of websites and apps, and to detect fraudulent use and take appropriate action.</p>
      <h4>・Examples of transmitted information</h4>
      <p>
        IP address<br/>
        URL of visited site or app screen name<br/>
        Date and time of browsing (used to calculate length of stay, etc.)<br/>
        Web browser and device type and version
      </p>
      <h4>・Privacy policy:<a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a></h4>
    </div>
  </div>
</template>


<script>
export default {
  name: 'HorusCookiePolicy',

  props: {
    lang: String
  },

  data () {
    return {
      displayLang: 'jp'
    }
  },

  watch: {
    lang: { handler: 'initLang', immediate: true }
  },

  methods: {
    initLang () {
      if (this.lang === 'en') {
        this.displayLang = 'en'
      }
    }
  }

}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mediaQuery.scss';

.horus-cookie-policy {
  padding: 10px;
  background-color: #fff;
  h1 {
    margin: 20px 0;
    font-size: 1.2em;
    font-weight: bold;
    color: #000;
    text-align: center;
  }
  h2 {
    margin: 20px 0;
    font-size: 0.9em;
    font-weight: bold;
    color: #000;
    &.top {
      margin-top: 0;
    }
    &.centering {
      text-align: center;
    }
  }
  h3 {
    margin: 3px 0;
    margin-top: 20px;
    font-size: 0.9em;
    font-weight: normal;
    color: #000;
  }
  h4 {
    margin: 3px 0;
    font-size: 0.9em;
    font-weight: normal;
    color: #000;
    border-bottom: 1px solid #ddd;
  }
  p {
    margin: 3px 0;
    font-size: 0.9em;
  }
  .cookie-policy-content.en {
    padding: 0 30px;
    word-break: normal;
    @include mediaQuery('phone') {
      padding: 0;
    }
    h1 {
      margin: 20px 0;
      font-size: 1em;
      font-weight: bold;
      color: #000;
      text-align: center;
    }
    h2 {
      margin: 20px 0 10px;
      padding-bottom: 3px;
      font-size: 1em;
      font-weight: bold;
      color: #000;
      border-bottom: 1px solid #000;
      &.centering {
        border: none;
        @include mediaQuery('phone') {
          margin-top: 0;
        }
      }
      &.first {
        margin-top: 0;
      }
    }
    h3 {
      margin: 3px 0;
      margin-top: 10px;
      font-size: 1em;
      font-weight: bold;
      color: #000;
    }
    h4 {
      margin: 3px 0;
      font-size: 1em;
      font-weight: normal;
      color: #000;
    }
    p {
      margin: 0;
      margin-top: 10px;
      margin-left: 50px;
      font-size: 1em;
      @include mediaQuery('phone') {
        margin-left: 10px;
      }
    }
    table.difinition-table {
      width: 100%;
      border: none;
      border-collapse: collapse;
      td {
        padding-top: 10px;
        border: none;
        vertical-align: top;
      }
      .index {
        width: 44px;
        padding-right: 5px;
        vertical-align: top;
        text-align: right;
        @include mediaQuery('phone') {
          width: 24px;
        }
      }
      .title {
        padding-right: 5px;
        white-space: nowrap;
        @include mediaQuery('phone') {
          width: 50px;
        }
      }
      .content {
        margin: 0;
        @include mediaQuery('phone') {
          width: calc(100% - 44px - 50px);
        }
      }
    }
    ol {
      margin: 0;
      margin-top: 10px;
      // margin-left: 50px;
      padding-inline-start: 50px;
      @include mediaQuery('phone') {
        padding-inline-start: 20px;
      }
      li {
        margin: 0;
        margin-top: 10px;
      }
      &.round-bracket {
        li {
          list-style-type: none;
          counter-increment: cnt;
        }
        li::before {
          display: inline-block;
          width: 45px;
          margin-left: -50px;
          padding-right: 5px;
          text-align: right;
          content: "(" counter(cnt) ")";
          @include mediaQuery('phone') {
            // margin-left: -30px;
          }
        }
      }
    }
  }
}
</style>
