<template>
  <div class="horus-term-of-use container">
    <div class="navbar">
      <div class="main-bar">
        <a href="/landing"><img src="/static/images/narrative_icon_w.png" class="navi-logo-icon"></a>
      </div>
    </div>
    <div class="contents">
      <div class="main-contents jp" v-if="displayLang === 'jp'">
        <h1>ナラティブブック利用規約<a href="/term_of_use/en" class="switch-lang">English</a></h1>
        <horus-rules
          :lang="displayLang"
           />
        <horus-cookie-policy
          :lang="displayLang"
          />
      </div>
      <div class="main-contents en" v-if="displayLang === 'en'">
        <h1>NARRATIVE BOOK Terms of Use<a href="/term_of_use/jp" class="switch-lang">日本語</a></h1>
        <horus-rules
          :lang="displayLang"
           />
        <horus-cookie-policy
          :lang="displayLang"
          />
      </div>
    </div>
    <horus-footer />
  </div>
</template>

<script>
import HorusRules from '@/components/common/HorusRules.vue'
import HorusCookiePolicy from '@/components/statics/HorusCookiePolicy.vue';
import HorusFooter from '@/components/common/HorusFooter.vue'

export default {
  name: 'HorusTermOfUse',

  components: {
    HorusRules,
    HorusCookiePolicy,
    HorusFooter
  },

  props: {
    lang: String
  },

  data () {
    return {
      displayLang: 'jp'
    }
  },

  watch: {
    '$attrs': { handler: 'initLang', immediate: true }
  },

  methods: {
    initLang () {
      if (this.lang[0] === 'en') {
        this.displayLang = 'en'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mediaQuery.scss';

.horus-term-of-use {
  background-color: #eee;
  .contents {
    min-height: calc(100% - 50px - 40px - 132px);
    padding: 20px 60px;
    @include mediaQuery('phone') {
      padding: 10px;
    }
    h1 {
      margin: 0;
      padding: 0;
      font-size: 1.4em;
      font-weight: bold;
      .switch-lang {
        padding-left: 20px;
        font-size: 12px;
        font-weight: normal;
      }
    }
    h2 {
      margin: 0;
      margin-top: 20px;
      font-size: 1.1em;
      font-weight: bold;
    }
    h3 {
      margin: 0;
      margin-top: 10px;
      font-size: 1em;
      font-weight: bold;
    }
    p {
      margin: 5px 0;
    }
    p.right-align {
      text-align: right;
    }
    ol {
      margin: 10px 0;
      padding-inline-start: 20px;
      li {
        margin: 8px;
      }
    }
    table {
      margin: 10px 0;
      width: 100%;
      border: 1px solid #999;
      border-collapse: collapse;
      &.handling {
        margin-left: 40px;
        width: calc(100% - 40px);
      }
      th {
        border: 1px solid #999;
        padding: 10px;
        background-color: #ddd;
        text-align: left;
      }
      td {
        border: 1px solid #999;
        padding: 10px;
      }
      .index {
        width: 30%;
      }
    }
    .main-contents.en {
      h1 {
        margin-bottom: 10px;
        font-size: 1.15em;
        text-align: center;
      }
    }
  }
}
</style>
